*, *:before, *:after{
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, p,
ul, ol, li, dl, dt, dd, a{
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
  line-height: 1;
}

a{
  color: inherit;
  text-decoration: none;
}

li{
  list-style: none;
}

