.c-hr{
  box-shadow: none;
  padding: 0;
  border: none;
  border-top: 1px solid #d4d2d2;
  margin: 20px 0;

  @include breakpoint(pc){
    margin: 40px 0;
  }
}



