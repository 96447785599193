.l-nav{
  display: none;
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 65px);
  background-image: url(/_assets/img/common/bg_nav.png);
  background-size: 100%;
  color: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 80px;

  @include breakpoint(pc){
    display: block;
    background: none;
    color: inherit;
    position: static;
    padding: 0;
    overflow: hidden;

    float: right;
    max-width: 610px;
    width: 63%;
    height: 130px;
    @include cfx;
  }
}

.l-nav-main{
  @include breakpoint(pc){
    width: 54%;
    height: 130px;
    float: left;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__item{
    @include breakpoint(pc){
      display: table-cell;
      //position: relative;
      height: 130px;
      border-right: 1px solid #d4d2d2;
      border-left: 1px solid #d4d2d2;

      &:hover{

        .l-nav-main__link{
          //background-color: #f1f1f1;
          background-color: #ececec;
        }

         > .l-nav-sub{
          display: block;
          height: 200px;
          opacity: 1;
          transition: opacity 0.6s;
          z-index: 2;
        }
      }
    }

    &:after{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(#d7d9d8, 0.25);

      @include breakpoint(pc){
        display: none;
      }
    }
  }

  &__link{
    display: block;
    position: relative;
    line-height: 65px;
    text-align: center;
    font-size: 2.4rem;

    @include breakpoint(pc){
      width: 100%;
      height: 130px;
      line-height: 1;
      // -ms-writing-mode: tb-rl;
      // writing-mode: vertical-rl;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.4s;
    }

    &:after{
      content: '';
      display: block;
      width: 17px;
      height: 16px;
      background-image: url(/_assets/img/common/icon_arrow-down.png);
      background-size: 100%;
      position: absolute;
      top: 25px;
      right: 20px;

      @include breakpoint(pc){
        display: none;
      }
    }

    &.is-open{
      &:after{
        background-image: url(/_assets/img/common/icon_arrow-up.png);
      }
    }

    >span{
      @include breakpoint(pc){
        display: block;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        width: 26px;
        font-size: 1.8rem;
        vertical-align: baseline;

        span{
          font-size: 2.6rem;
        }
      }
    }
  }
}

.l-nav-sub{
  display: none;
  margin: 0 0 25px;

  @include breakpoint(pc){
    display: block !important;
    margin: 0;
    background-image: url(/_assets/img/common/bg_nav.png);
    width: 100%;
    height: 200px;
    height: 0;
    position: fixed;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 0;
    opacity: 0;
    transition: opacity 0.2s, height 0s 0.2s;
    overflow: hidden;
  }

  &.is-pray{
    @include breakpoint(pc){
      padding-right: 130px;
    }
  }

  &.is-know{
    @include breakpoint(pc){
      padding-left: 90px;
    }
  }

  &.is-see{
    @include breakpoint(pc){
      padding: 0 0 0 310px;
    }
  }

  .l-nav-sub__item:last-child{

    @include breakpoint(pc){
      border-right: 1px solid #62676e;
    }
  }

  &__item{
    @include breakpoint(pc){
      display: inline-block;
      vertical-align: top;
      height: 200px;
      border-left: 1px solid #62676e;
    }
  }

  &__link{
    display: block;
    position: relative;
    line-height: 50px;
    text-align: center;
    font-size: 1.6rem;

    @include breakpoint(pc){
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      width: 50px;
      line-height: 50px;
      padding-top: 35px;
      height: 100%;
      text-align: left;
      transition: background-color 0.4s;

      &:hover{
        background-color: rgba(#000, 0.25);
      }
    }
  }
}

.l-nav-menu{
  margin: 44px 0 30px;
  text-align: center;

  @include breakpoint(pc){
    float: right;
    width: 20%;
    text-align: left;
    margin: 40px 0 0;
  }

  &__item{
    display: inline-block;

    @include breakpoint(pc){
      display: block;
    }

    & + .l-nav-menu__item{
      margin: 0 0 0 30px;

      @include breakpoint(pc){
        margin: 15px 0 0;
      }
    }
  }

  &__link{
    display: inline-block;
    padding: 0 0 0 30px;
    line-height: 22px;
    font-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 22px;

    @include breakpoint(pc){
      font-size: 1.4rem;
      background-size: 20px;
      padding: 0 0 0 27px;

      &:hover{
        text-decoration: underline;
      }
    }

    &.is-access{
      background-image: url(/_assets/img/common/icon_access.png);

      @include breakpoint(pc){
        background-image: url(/_assets/img/common/icon_access2.png);
      }
    }

    &.is-apply{
      background-image: url(/_assets/img/common/icon_apply.png);

      @include breakpoint(pc){
        background-image: url(/_assets/img/common/icon_apply2.png);
      }
    }
  }
}

.l-nav-menu-sub{
  text-align: center;

  @include breakpoint(pc){
    display: none;
  }

  &__item{
    display: inline-block;
    font-size: 0;

    &:after{
      content: '/';
      display: inline-block;
      padding: 0 5px 0 10px;
      font-size: 1.4rem;
    }

    &:last-child{
      &:after{
        display: none;
      }
    }
  }

  &__link{
    font-size: 1.4rem;
  }
}
