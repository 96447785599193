.c-button{
  @include cfx;
  margin: 0;

  @include breakpoint(pc){
    margin: 0 -20px;
  }

  a{
    display: inline-block;
    background-color: rgba(#37322f, 0.9);
    color: #fff;
    text-align: center;
    padding: 15px 45px 15px 15px;
    position: relative;
    min-width: 100%;
    margin: 2px 0;

    @include breakpoint(pc){
      min-width: 310px;
      margin: 0 20px;
      padding: 20px 45px;
      transition: background-color 0.4s;

      &:hover{
        background-color: rgba(#37322f, 1);

        &:after{
          right: 10px;
        }
      }
    }

    &:after{
      content: '';
      width: 24px;
      height: 6px;
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      background-image: url(/_assets/img/common/icon_arrow-right.svg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 71px;
      margin: -3px 0 0;
      transition: right 0.3s;
    }

    &.is-pdf{
      background-image: url(/_assets/img/common/icon_pdf.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 24px;

      &:after{
        display: none;
      }
    }
  }
}
