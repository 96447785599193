.article{
  margin: 0 0 40px;

  @include breakpoint(pc){
    margin: 0 0 80px;
  }

  .article__date{
    text-align: right;
    margin: 0 0 15px;

    @include breakpoint(pc){
      font-size: 1.6rem;
      margin: 0 0 30px;
    }
  }

  h1{
    margin: 0 0 40px;
    font-size: 2.1rem;
    font-weight: bold;
    border-bottom: 1px solid #d4d2d2;
    padding: 0 0 10px;
    line-height: 1.4;
    position: relative;

    @include breakpoint(pc){
      font-size: 2.8rem;
      margin: 0 0 80px;
      padding: 0 0 20px;
    }

    &:before,
    &:after{
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      background-color: #d4d2d2;
      border-radius: 10px;
      position: absolute;
      bottom: -2px;
    }

    &:before{
      left: 0;
    }

    &:after{
      right: 0;
    }
  }

  h2{
    font-size: 1.8rem;
    margin: 0 0 40px;
    border-left: 3px solid #332c2b;
    padding: 3px 0 3px 6px;
    font-weight: bold;

    @include breakpoint(pc){
      font-size: 2rem;
      margin: 0 0 80px;
    }
  }

  h3{
    margin: 0 0 40px;
    font-size: 1.8rem;
    font-weight: bold;

    @include breakpoint(pc){
      font-size: 2rem;
      margin: 0 0 80px;
    }
  }

  ul{
    @extend .c-list--dot;
    margin: 0 0 40px;

    @include breakpoint(pc){
      margin: 0 0 80px;
    }

    li{}
  }

  a{
    display: block;
    margin: 0 0 20px;
    background-color: rgba(#37322f, 0.9);
    color: #fff;
    text-align: center;
    padding: 15px 15px 15px 15px;
    position: relative;
    min-width: 100%;

    @include breakpoint(pc){
      min-width: 310px;
      width: 310px;
      margin: 0 0 40px;
      padding: 20px 45px;
      transition: background-color 0.4s;

      &:hover{
        background-color: rgba(#37322f, 1);

        &:after{
          right: 10px;
        }
      }
    }

    &:after{
      content: '';
      width: 24px;
      height: 6px;
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      background-image: url(/_assets/img/common/icon_arrow-right.svg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 71px;
      margin: -3px 0 0;
      transition: right 0.3s;
    }

    &.is-blank{
      background-image: url(/_assets/img/common/icon_blank.svg);
      background-repeat: no-repeat;
      background-position: right 20px center;
      background-size: 12px;

      @include breakpoint(pc){
        background-size: 16px;
        background-position: right 15px center;
      }

      &:after{
        display: none;
      }
    }

    &.is-pdf{
      background-image: url(/_assets/img/common/icon_pdf.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 24px;

      &:after{
        display: none;
      }
    }
  }

  p{
    @extend .c-txt;
    margin: 0 0 40px;

    @include breakpoint(pc){
      margin: 0 0 80px;
    }
  }

  img{
    display: block;
    margin: 0 auto 40px;
    max-width: 100%;

    @include breakpoint(pc){
      margin: 0 auto 80px;
    }
  }

  table{
    @extend .c-table;
    width: 100%;
    border-collapse: collapse;
    font-size: 1.4rem;
    border: 1px solid #dddddd;
    margin: 0 0 40px;

    @include breakpoint(pc){
      margin: 0 0 80px;
    }

    tr:first-child{
      th{
        text-align: center;
      }
    }

    th,
    td{
      text-align: left;
    }

    th{
      min-width: 8em;
    }
  }
}
