.c-carousel{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoint(pc){
    display: block;
    margin: 0 -20px;
  }

  &__block{
    width: 47%;
    margin: 0 0 20px;

    @include breakpoint(pc){
      width: auto;
      margin: 0;
      display: block;
      height: auto;
      padding: 0 20px;
      outline: none;

      &[aria-hidden="true"]{
        opacity: 0.3;
      }
    }
  }

  .slick-list{
    overflow: visible;
  }

  .slick-dots{
    margin: 15px 0 0;
    text-align: center;

    @include breakpoint(pc){
      margin: 25px 0 0;
    }

    li{
      display: inline-block;
      font-size: 0;

      &.slick-active{
        button{
          background-color: #332e2b;
          border: 1px solid #332e2b;
        }
      }
    }

    button{
      display: inline-block;
      width: 8px;
      height: 8px;
      font-size: 0;
      background: none;
      border: none;
      border: 1px solid #c9c7c6;
      border-radius: 50%;
      padding: 0;
      margin: 0 6px;

      @include breakpoint(pc){
        width: 10px;
        height: 10px;
        margin: 0 7px;
        cursor: pointer;
        transition: background-color 0.4s;

        &:hover{
          background-color: rgba(#c9c7c6, 0.3);
        }
      }
    }
  }

  .slick-arrow{
    display: none;

    @include breakpoint(pc){
      display: block;
      position: absolute;
      top: 40%;
      z-index: 10;
      width: 14px;
      height: 16px;
      font-size: 0;
      background: none;
      border: none;
      outline: none;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .slick-prev{
    left: -100px;
    background-image: url(/_assets/img/common/icon_arrow-left.png);
  }

  .slick-next{
    right: -100px;
    background-image: url(/_assets/img/common/icon_arrow-right2.png);
  }
}
