// .c-grid--1{
//   @include breakpoint(pc){
//     width: 760px;
//     margin: 0 auto;
//   }

//   .c-grid__block{
//     @include breakpoint(pc){}
//   }
// }

.c-grid--2{

  @include breakpoint(pc){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }

  .c-grid__block{
    @include breakpoint(pc){
      width: 50%;
      padding: 0 20px;
    }

    & + .c-grid__block{
      margin: 30px 0 0;

      @include breakpoint(pc){
        margin: 0;
      }
    }
  }
}

.c-grid--3{

  @include breakpoint(pc){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }

  .c-grid__block{

    @include breakpoint(pc){
      width: calc(100% / 3);
      padding: 0 20px;
    }

    & + .c-grid__block{
      margin: 30px 0 0;

      @include breakpoint(pc){
        margin: 0;
      }
    }
  }
}

.c-grid--2-1{

  @include breakpoint(pc){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-grid__block{

    &:first-child{
      margin: 0 0 30px;

      @include breakpoint(pc){
        width: 73%;
      }
    }

    &:last-child{
      @include breakpoint(pc){
        width: 27%;
        padding: 0 0 0 40px;
      }
    }
  }
}

.c-grid--1-2{

  @include breakpoint(pc){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-grid__block{

    &:first-child{
      margin: 0 0 30px;

      @include breakpoint(pc){
        order: 2;
        width: 73%;
      }
    }

    &:last-child{
      @include breakpoint(pc){
        order: 1;
        width: 27%;
        padding: 0 40px 0 0;
      }
    }
  }
}
