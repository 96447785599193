.c-anchor{
  border-top: 1px solid #e8e7e7;

  @include breakpoint(pc){
    border-top: none;
    text-align: center;
  }

  li{
    border-bottom: 1px solid #e8e7e7;

    @include breakpoint(pc){
      display: inline-block;
      border-bottom: none;
      border-right: 1px solid #e8e7e7;

      &:last-child{
        border-right: none;
      }
    }
  }

  a{
    display: block;
    text-align: center;
    font-size: 1.4rem;
    padding: 15px 30px;
    background-image: url(/_assets/img/common/icon_arrow-down.png);
    background-repeat: no-repeat;
    background-position: 18px center;
    background-size: 12px;

    @include breakpoint(pc){
      padding: 8px 30px 8px 55px;
      background-position: 30px center;

      &:hover{
        text-decoration: underline;
      }
    }
  }
}

