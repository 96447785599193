.c-img{

  @include breakpoint(pc){
  }

  > img{
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}

.c-img--full{
  margin: 0 -30px;

  @include breakpoint(pc){
    margin: 0 auto;
  }

  > img{
    display: block;
    width: 100%;
    height: auto;
  }
}

.c-img--wide{
  width: 100%;
  max-width: none;
}

.c-img-txt{

  @include breakpoint(pc){
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__img{
    margin: 0 0 30px;

    @include breakpoint(pc){
      width: ((570 / 980) * 100)+%;
      margin: 0;
    }

    img{
      display: block;
      width: 100%;

      @include breakpoint(pc){
        margin: 0 0 5px;
      }
    }

    span{
      color: #777;
      font-size: 1rem;
      line-height: 1.6;

      @include breakpoint(pc){
        font-size: 1.4rem;
      }
    }
  }

  &__txt{
    @include breakpoint(pc){
      width: ((410 / 980) * 100)+%;
      padding: 0 0 0 40px;
    }
  }
}

.c-img-txt.is-reverse{
  .c-img-txt__img{
    @include breakpoint(pc){
      order: 2;
    }
  }
  .c-img-txt__txt{
    @include breakpoint(pc){
      order: 1;
      padding: 0 40px 0 0;
    }
  }
}
