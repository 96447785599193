.l-wrap{
  width: 100%;
  position: relative;
  padding: 65px 0 0;

  @include breakpoint(pc){
    padding: 0;
    overflow: hidden;
  }
}
