.c-txt{
  font-size: 1.4rem;
  line-height: 1.8;

  @include breakpoint(pc){
    font-size: 1.6rem;
    line-height: 2;
  }

  &__link{
    text-decoration: underline;
    padding: 0 5px 0 0;

    &[target="_blank"]{
      padding: 0 15px 0 0;
      background-image: url(/_assets/img/common/icon_blank.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 12px;
      margin: 0 5px 0 0;
    }

    @include breakpoint(pc){
      &:hover{
        text-decoration: none;
      }
    }
  }
}

