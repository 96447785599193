.top{
  &__pagetop{
    text-align: center;

    @include breakpoint(pc){
      text-align: right;
      padding: 0 30px;
      margin: 0 0 5px;
    }

    a{
      display: inline-block;
      width: 18px;
      height: 18px;
      background-image: url(/_assets/img/common/btn_pagetop.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
}

.top-section{
  margin: 40px 0;

  @include breakpoint(pc){
    margin: 80px 0;
  }

  &__inner{
    @include breakpoint(pc){
      padding: 0 10px;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__ttl{
    font-size: 1.8rem;
    text-align: center;
    padding: 0 0 15px;
    margin: 0 0 40px;
    background-image: url(/_assets/img/common/line.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 43px;
    font-weight: bold;

    @include breakpoint(pc){
      font-size: 2rem;
      padding: 0 0 30px;
      margin: 0 0 35px;
    }
  }
}

.top-mv{
  width: 100%;
  margin: -45px 0 40px;

  @include breakpoint(pc){
    margin: 0 0 80px;
  }

  &__slide{
    width: 100%;
    height: 275px;
    background-position: center;
    background-size: cover;

    position: relative;

    @include breakpoint(pc){
      height: auto;
    }

    img{
      display: none;

      @include breakpoint(pc){
        display: block;
        width: 100%;
      }
    }
  }

  .slick-dots{
    margin: 15px 0 0;
    text-align: center;

    @include breakpoint(pc){
      margin: 25px 0 0;
    }

    li{
      display: inline-block;
      font-size: 0;

      &.slick-active{
        button{
          background-color: #332e2b;
          border: 1px solid #332e2b;
        }
      }
    }

    button{
      display: inline-block;
      width: 8px;
      height: 8px;
      font-size: 0;
      background: none;
      border: none;
      border: 1px solid #c9c7c6;
      border-radius: 50%;
      padding: 0;
      margin: 0 6px;

      @include breakpoint(pc){
        width: 10px;
        height: 10px;
        margin: 0 7px;
        cursor: pointer;
        transition: background-color 0.4s;

        &:hover{
          background-color: rgba(#c9c7c6, 0.3);
        }
      }
    }
  }
}

.top-news{
  padding: 0 30px;
  margin: 0 0 40px;
}

.top-menu{
  color: #fff;

  @include breakpoint(pc){
    height: 550px;
    background-position: center;
    background-size: cover;
    background-image: url(/_assets/img/top/menu_bg.png);
  }

  .top-section__inner{
    @include breakpoint(pc){
      position: relative;
    }
  }

  &__list{
    @include breakpoint(pc){
      height: 550px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 3;
    }
  }

  &__item{
    height: 210px;
    background-position: center;
    background-size: cover;
    position: relative;

    @include breakpoint(pc){
      width: 33.3333%;
      height: 100%;
    }

    &.is-pray{
      background-image: url(/_assets/img/top/menu01.jpg);

      @include breakpoint(pc){
        background-image: url(/_assets/img/top/menu01-pc.jpg);
      }
    }

    &.is-know{
      background-image: url(/_assets/img/top/menu02.jpg);

      @include breakpoint(pc){
        background-image: url(/_assets/img/top/menu02-pc.jpg);
      }

      .top-menu__ttl{
        right: auto;
        left: 15px;

        @include breakpoint(pc){
          left: auto;
          right: 50%;
        }
      }

      .top-menu__txt{
        text-align: left;
        right: auto;
        left: 75px;
      }
    }

    &.is-see{
      background-image: url(/_assets/img/top/menu03.jpg);

      @include breakpoint(pc){
        background-image: url(/_assets/img/top/menu03-pc.jpg);
      }
    }
  }

  &__link{
    display: block;
    height: 210px;

    @include breakpoint(pc){
      height: 100%;

      &.is-disable{
        pointer-events: none;
      }
    }
  }

  &__ttl{
    display: block;
    font-size: 4.2rem;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    bottom: 25px;
    right: 15px;

    @include breakpoint(pc){
      bottom: 50%;
      right: 50%;
      margin: 0 -0.5em -1em 0;
      font-size: 3.7rem;
      vertical-align: middle;
    }

    span{
      font-size: 5.4rem;

      @include breakpoint(pc){
        font-size: 4.8rem;
      }
    }
  }

  &__txt{
    text-align: right;
    position: absolute;
    bottom: 25px;
    right: 77px;
    font-size: 1.2rem;
    line-height: 1.6;

    @include breakpoint(pc){
      display: none;
    }
  }

  &__img{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%) translate3d(0,0,0);
    width: 980px;
    max-width: none;
    transition: width 25s linear;
  }

  .top-menu__cover.is-show{
    .top-menu__img{
      width: 1274px;
    }
  }

  &__cover{
    display: none;

    @include breakpoint(pc){
      position: absolute;
      top: 0;
      left: 10px;
      z-index: 2;
      width: 100%;
      max-width: 980px;
      height: 550px;
      overflow: hidden;

      &:before,
      &:after{
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #ccc;
        position: absolute;
        top: 0;
        z-index: 3;
      }

      &:before{
        left: 33.3333%;
      }

      &:after{
        left: 66.6666%;
      }
    }

    &.is-current{
      z-index: 2;
    }

    &.is-pray{

      .top-menu__cover-ttl{
        left: 15%;
      }

      .top-menu__cover-txt{
        left: 15%;
        margin: 0 0 0 -3.5em;
      }
    }

    &.is-know{

      .top-menu__cover-ttl{
        left: 50%;
      }

      .top-menu__cover-txt{
        left: 50%;
        margin: 0 0 0 -2.5em;
      }
    }

    &.is-see{

      .top-menu__cover-ttl{
        left: 83%;
      }

      .top-menu__cover-txt{
        left: 83%;
        margin: 0 0 0 -2.5em;
      }
    }
  }

  &__cover-ttl{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    top: 100px;
    z-index: 5;
    font-size: 3.7rem;
    margin: 0 0 0 -0.5em;

    span{
      font-size: 4.8rem;
    }
  }

  &__cover-txt{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    top: 240px;
    z-index: 5;
    line-height: 2;
  }
}

.top-event{
  padding: 0 15px;
  border-bottom: 1px solid #e4e3e3;

  &__list{
    padding: 0 0 50px;

    @include breakpoint(pc){
      padding: 0 0 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item{

    @include breakpoint(pc){
      width: 30.5%;
    }

    & + .top-event__item{
      margin: 30px 0 0;

      @include breakpoint(pc){
        margin: 0;
      }
    }
  }

  &__link{
    display: block;
    min-height: 130px;
    padding: 0 0 15px 180px;
    position: relative;

    @include breakpoint(pc){
      padding: 0 0 40px;

      &:hover{
        .top-event__img{
          &:after{
            opacity: 1;
          }
        }

        .top-event__ttl{
          text-decoration: underline;
        }

        &:after{
          right: -10px;
        }
      }
    }

    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 25px;
      height: 6px;
      background-image: url(/_assets/img/common/icon_arrow-right.svg);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 72px;

      @include breakpoint(pc){
        transition: right 0.4s;
      }
    }
  }

  &__img{
    width: 165px;
    height: 110px;
    text-align: center;
    background-color: rgba(#c9c7c6, 0.3);
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(pc){
      position: relative;
      width: 100%;
      height: auto;
      margin: 0 0 25px;
      transition: background-color 0.4s;

      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(#000, 0.05);
        transition: opacity 0.4s;
      }
    }

    img{
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__ttl{
    font-size: 1.4rem;
    line-height: 1.3;
    margin: 0 0 10px;

    @include breakpoint(pc){
      text-align: center;
      font-size: 1.8rem;
      margin: 0 0 5px;
    }
  }

  &__date{
    font-size: 1rem;
    position: absolute;
    top: 120px;
    left: 0;
    width: 165px;
    text-align: center;

    @include breakpoint(pc){
      font-size: 1.2rem;
      width: auto;
      position: static;
      margin: 0 0 20px;
    }
  }

  &__txt{
    font-size: 1.2rem;
    line-height: 1.3;

    @include breakpoint(pc){
      font-size: 1.4rem;
      line-height: 2;
    }
  }
}

.top-apply{
  padding: 0 15px 40px;
  border-bottom: 1px solid #e4e3e3;

  @include breakpoint(pc){
    padding: 0 0 80px;
  }

  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(pc){
    }
  }

  &__item{
    width: 47%;
    margin: 0 0 20px;

    @include breakpoint(pc){
      width: 22%;
    }

    &:nth-last-child(-n+2){
      margin: 0;
    }
  }

  &__link{
    display: block;

    @include breakpoint(pc){
      &:hover{
        .top-apply__ttl{
          text-decoration: underline;
        }
        .top-apply__img:after{
          opacity: 1;
        }
      }
    }
  }

  &__img{

    @include breakpoint(pc){
      margin: 0 0 25px;
      position: relative;

      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(#000, 0.3);
        transition: opacity 0.4s;
      }
    }

    img{
      display: block;
      width: 100%;
    }
  }

  &__ttl{
    font-size: 1.4rem;
    text-align: center;
    margin: 10px 0;

    @include breakpoint(pc){
      margin: 0 0 15px;
      font-size: 1.6rem;
    }
  }

  &__txt{
    font-size: 1.2rem;
    line-height: 1.6;

    @include breakpoint(pc){
      font-size: 1.4rem;
      line-height: 2;
    }
  }
}

.top-relation{
  padding: 0 15px;

  @include breakpoint(pc){
    margin: 0 0 40px;
  }

  &__shuha{
    display: block;
    background-color: #fff;
    border: 1px solid #e8e7e7;
    padding: 20px 0 16px;
    margin: 0 0 40px;

    @include breakpoint(pc){
      width: 380px;
      margin: 0 auto 55px;
      padding: 23px 0 20px;
      transition: background-color 0.4s;

      &:hover{
        background-color: #f2f2f2;
      }
    }

    img{
      display: block;
      width: 216px;
      margin: 0 auto 10px;

      @include breakpoint(pc){
        width: 238px;
        margin: 0 auto 15px;
      }
    }

    span{
      display: block;
      text-align: center;
      font-size: 1.2rem;

      @include breakpoint(pc){
        line-height: 1;
      }

      &:after{
        content: '';
        display: inline-block;
        width: 10px;
        height: 1em;
        margin: 0 0 0 3px;
        background-image: url(/_assets/img/common/icon_blank.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px;

        @include breakpoint(pc){
          width: 14px;
          background-size: 14px;
          margin: 0 0 0 10px;
        }
      }
    }
  }

  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item{
    width: 47%;
    margin: 0 0 40px;

    @include breakpoint(pc){
      width: 22%;
      margin: 0;
    }

    &:nth-last-child(-n+2){
      margin: 0;
    }
  }

  &__link{
    display: block;
    padding: 0 0 0 38px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 30px;
    min-height: 30px;

    @include breakpoint(pc){
      padding: 0 0 0 55px;
      background-size: 40px;
      min-height: 40px;

      &:hover{
        .top-relation__ttl{
          text-decoration: underline;
        }
      }
    }

    &.is-01{
      background-image: url(/_assets/img/top/relation01.png);
    }

    &.is-02{
      background-image: url(/_assets/img/top/relation02.png);
    }

    &.is-03{
      background-image: url(/_assets/img/top/relation03.png);
    }

    &.is-04{
      background-image: url(/_assets/img/top/relation04.png);
    }
  }

  &__lead{
    display: block;
    font-size: 1rem;
    margin: 0 0 5px;

    @include breakpoint(pc){
      font-size: 1.2rem;
      margin: 0 0 8px;
    }
  }

  &__ttl{
    font-size: 1.6rem;

    @include breakpoint(pc){
      font-size: 1.5rem;
    }

    &:after{
      content: '';
      display: inline-block;
      width: 12px;
      height: 1em;
      margin: 0 0 0 5px;
      background-image: url(/_assets/img/common/icon_blank.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;

      @include breakpoint(pc){
        width: 14px;
        background-size: 14px;
        margin: 0 0 0 10px;
      }
    }
  }
}
