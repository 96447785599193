@mixin breakpoint( $device ){

  @if($device == 'pc'){
    @media screen and (min-width: 800px) { @content; }
  }

  @if($device == 'sp'){
    @media screen and (max-width: 799px) { @content; }
  }

}

@mixin cfx{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}
