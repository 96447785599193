.l-footer{
  border-top: 1px solid #e2e1e1;
  background-color: rgba(#e2e1e1, 0.3);
  text-align: center;
  padding: 40px 0 55px;
  margin: 40px 0 0;

  @include breakpoint(pc){
    text-align: left;
    padding: 80px 0 85px;
  }

  &__inner{
    @include breakpoint(pc){
      @include cfx;
      padding: 0 10px;
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}

.l-footer-main{

  @include breakpoint(pc){
    max-width: 250px;
    width: 31%;
    float: left;
  }

  &__name{
    font-size: 1rem;

    @include breakpoint(pc){
      text-align: center;
      font-size: 1.4rem;
    }

    img{
      display: inline-block;
      width: 140px;
      margin: 10px 0 5px;

      @include breakpoint(pc){
        width: 186px;
        margin: 15px 0 10px;
      }
    }

    span{
      font-size: 1.5rem;

      @include breakpoint(pc){
        font-size: 1.6rem;
      }
    }
  }

  &__address{
    margin: 18px 0 30px;
    font-size: 1.2rem;
    line-height: 1.6;

    @include breakpoint(pc){
      margin: 25px 0 0;
      line-height: 1.8;
    }

    span{
      margin: 0 10px 0 0;

      @include breakpoint(pc){
        display: block;
      }
    }
  }
}

.l-footer-sitemap{
  display: none;

  @include breakpoint(pc){
    display: block;
    max-width: 660px;
    width: 68%;
    float: right;
    @include cfx;
  }

  &__column{
    width: 25%;
    float: left;
  }

  &__ttl{
    font-size: 2.2rem;
    margin: 0 0 20px;

    @include breakpoint(pc){
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__list{}

  &__item{
    margin: 15px 0 0;
  }

  &__link{
    font-size: 1.3rem;

    @include breakpoint(pc){
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.l-footer-sitemap-menu{
  margin: 0 0 30px;

  &__item{
    margin: 0 0 10px;
  }

  &__link{
    display: inline-block;
    height: 20px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    background-size: 20px;
    line-height: 20px;
    padding: 0 0 0 30px;

    @include breakpoint(pc){
      &:hover{
        text-decoration: underline;
      }
    }

    &.is-access{
      background-image: url(/_assets/img/common/icon_access2.png);
    }

    &.is-apply{
      background-image: url(/_assets/img/common/icon_apply2.png);
    }
  }
}

.l-footer-bottom{
  background-image: url(/_assets/img/common/footer_logo.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 40px;
  padding: 50px 0 0;

  @include breakpoint(pc){
    clear: both;
    padding: 150px 0 0;
    text-align: center;
    background-position: center 80px;
    background-size: 54px;
  }
}
