.c-block--xl{
  margin-bottom: 80px;

  @include breakpoint(pc){
    margin-bottom: 120px;
  }
}

.c-block--l{
  margin-bottom: 65px;

  @include breakpoint(pc){
    margin-bottom: 100px;
  }
}

.c-block--m{
  margin-bottom: 65px;

  @include breakpoint(pc){
    margin-bottom: 80px;
  }
}

.c-block--s{
  margin-bottom: 35px;

  @include breakpoint(pc){
    margin-bottom: 50px;
  }
}

.c-block--xs{
  margin-bottom: 10px;

  @include breakpoint(pc){
    margin-bottom: 10px;
  }
}

