.c-breadcrumb{
  display: none;

  @include breakpoint(pc){
    display: block;
    padding: 15px 0;
    margin: 0 0 50px;
  }

  li{
    display: inline-block;
    font-size: 1.2rem;

    &:first-child{
      a,
      span{
        &:before{
          display: none;
        }
      }
    }
  }

  a{
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }

  a,
  span{

    &:before{
      content: '>';
      display: inline-block;
      height: 1em;
      padding: 0 5px 0 3px;
      text-align: center;
    }
  }
}
