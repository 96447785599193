.c-contents{
  padding: 0 30px;

  @include breakpoint(pc){
    padding: 0 22px;
    max-width: 1024px;
    margin: 0 auto;
  }
}

.c-contents--narrow{
  padding: 0 30px;

  @include breakpoint(pc){
    padding: 0 22px;
    max-width: 804px;
    margin: 0 auto;
  }
}

