.c-list--dot{
  padding: 0 0 0 20px;

  @include breakpoint(pc){}

  li{
    list-style-type: square;
    font-size: 1.4rem;
    line-height: 1.8;

    @include breakpoint(pc){
      font-size: 1.6rem;
      line-height: 2;
    }

    & + li{
      margin: 20px 0 0;
    }
  }
}

.c-list--num{
  padding: 0 0 0 20px;

  @include breakpoint(pc){}

  li{
    list-style-type: decimal;
    font-size: 1.4rem;
    line-height: 1.8;

    @include breakpoint(pc){
      font-size: 1.6rem;
      line-height: 2;
    }

    & + li{
      margin: 20px 0 0;
    }
  }
}

.c-list--link{

  @include breakpoint(pc){}

  li{
    font-size: 1.4rem;
    line-height: 1.8;
    background-image: url(/_assets/img/common/icon_arrow-right.svg);
    background-position: left -45px center;
    background-repeat: no-repeat;
    background-size: 71px;
    padding: 0 0 0 35px;

    @include breakpoint(pc){
      font-size: 1.6rem;
    }

    & + li{
      margin: 16px 0 0;

      @include breakpoint(pc){
        margin: 10px 0 0;
      }
    }
  }

  a{
    &[target="_blank"]{
      padding: 0 15px 0 0;
      background-image: url(/_assets/img/common/icon_blank.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 12px;
      margin: 0 5px 0 0;
    }

    &:visited{
      color: #777777;
    }

    @include breakpoint(pc){
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.c-list--button{
  // text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 -10px;

  @include breakpoint(pc){
    justify-content: center;
    // display: block;
    // text-align: center;
    // margin: 0 -5px -2px;
    margin: 0 0 -2px;
  }

  li{
    display: inline-block;
    width: 50%;
    padding: 0 5px 10px 0;
    font-size: 0;

    @include breakpoint(pc){
      padding: 0 0 2px;
      // max-width: 160px;
      width: calc(100% / 6);
    }

    &:nth-of-type(even){
      padding: 0 0 10px 5px;

      @include breakpoint(pc){
        padding: 0 5px 2px;
      }
    }
  }

  a{
    display: block;
    background-color: rgba(#37322f, 0.9);
    color: #fff;
    text-align: center;
    padding: 15px 0;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.2;

    @include breakpoint(pc){
      width: 100%;
      margin: 0 auto;
      padding: 23px 0;
      transition: background-color 0.4s;
      font-size: 1.6rem;

      &:hover{
        background-color: rgba(#37322f, 1);
      }
    }
  }
}

.c-list--backnumber{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  @include breakpoint(pc){
    margin: 0 -15px;
    justify-content: flex-start;
  }

  li{
    width: 50%;
    padding: 0 10px;
    margin: 0 0 40px;

    @include breakpoint(pc){
      //width: calc(100% / 6);
      width: 16.66663%;
      padding: 0 15px;
    }

    .c-flower{
      display: none;
    }
  }

  a{
    display: block;
  }

  img{
    display: block;
    max-width: none;
    width: 100%;
  }

  p{
    font-size: 1.2rem;
    text-align: center;
    padding: 10px 0 0;

    @include breakpoint(pc){
      padding: 15px 0 0;
      font-size: 1.4rem;
    }
  }
}

.c-list--news{
  margin: -5px 0 15px;

  @include breakpoint(pc){
    @include cfx;
    margin: 0 0 -20px;
  }

  &__date{
    margin: 0 0 2px;
    font-size: 1.4rem;
    line-height: 1.6;

    @include breakpoint(pc){
      width: 15.3%;
      float: left;
      clear: both;
      font-size: 1.6rem;
      margin: 0 0 20px;
      position: relative;
    }

    &:after{
      @include breakpoint(pc){
        content: '';
        display: block;
        width: 24px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 18px;
        margin: -7px 0 0;
        background-image: url(/_assets/img/common/icon_arrow-right.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 72px;
      }
    }
  }

  &__txt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include breakpoint(pc){
      width: 84%;
      float: right;
      margin: 0 0 20px;
    }

    & + .top-news__date{
      margin-top: 15px;

      @include breakpoint(pc){
        margin-top: 0;
      }
    }
  }

  &__link{
    font-size: 1.4rem;
    line-height: 1.6;

    @include breakpoint(pc){
      font-size: 1.6rem;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  &__more{
    text-align: right;
    font-size: 1.4rem;

    @include breakpoint(pc){
      padding: 10px 0 0;
    }

    a{
      display: inline-block;
      padding: 0 0 10px;
      background-image: url(/_assets/img/common/icon_arrow-right.svg);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 72px;

      @include breakpoint(pc){
        background-size: 72px;
        background-position: 0 bottom;

        &:hover{
          animation-duration: 0.4s;
          animation-name: top-news__more;
        }
      }
    }
  }
}

.c-list--news-archive{
  border-top: 1px solid #e8e7e7;
  @include breakpoint(pc){
    border-top: none;
    text-align: center;
  }

  li{
    border-bottom: 1px solid #e8e7e7;

    @include breakpoint(pc){
      display: inline-block;
      border-bottom: none;
      border-right: 1px solid #e8e7e7;

      &:last-child{
        border-right: none;
      }
    }
  }

  a{
    display: block;
    text-align: center;
    font-size: 1.6rem;
    padding: 15px 30px;
    &.is-current {
      text-decoration: underline;
    }
    @include breakpoint(pc){
      padding: 8px 30px 8px 30px;

      &:hover{
        text-decoration: underline;
      }
    }
  }
}
