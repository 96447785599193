*, *:before, *:after {
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, p,
ul, ol, li, dl, dt, dd, a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
  line-height: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  font-size: 10px;
  font-weight: 400;
  color: #332c2b;
  background-image: url(/_assets/img/common/bg-sp.png);
  background-size: 50px;
}

body {
  font-size: 1.6rem;
  font-family: "yu-mincho-pr6n", sans-serif;
  font-style: normal;
  font-weight: 400;
}

a {
  color: inherit;
}

img {
  max-width: 100%;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.l-wrap {
  width: 100%;
  position: relative;
  padding: 65px 0 0;
}

@media screen and (min-width: 800px) {
  .l-wrap {
    padding: 0;
    overflow: hidden;
  }
}

.l-header {
  background-image: url(/_assets/img/common/bg-sp.png);
  background-size: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 65px;
  padding: 0 20px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 800px) {
  .l-header {
    height: 130px;
    position: relative;
    z-index: 1;
  }
}

@media screen and (min-width: 800px) {
  .l-header__inner {
    padding: 0 10px;
    max-width: 1000px;
    margin: 0 auto;
  }
  .l-header__inner:after {
    content: '';
    display: block;
    clear: both;
  }
}

@media screen and (min-width: 800px) {
  .l-header__logo {
    float: left;
    width: 370px;
    width: 36%;
  }
}

.l-header__logo a {
  display: inline-block;
  background-image: url(/_assets/img/common/logo-sp.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 117px;
  line-height: 65px;
  padding: 0 0 0 130px;
  font-size: 1.3rem;
}

@media screen and (min-width: 800px) {
  .l-header__logo a {
    display: block;
    background-image: url(/_assets/img/common/logo-pc.png);
    background-position: 0 bottom;
    background-size: 217px;
    width: 217px;
    height: 96px;
    padding: 80px 0 0 90px;
    line-height: 1;
    font-size: 1.6rem;
  }
}

.l-header__btn {
  display: block;
  width: 32px;
  height: 17px;
  position: absolute;
  top: 24px;
  right: 20px;
  background-image: url(/_assets/img/common/btn_nav.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

@media screen and (min-width: 800px) {
  .l-header__btn {
    display: none;
  }
}

.l-header__btn.is-open {
  background-image: url(/_assets/img/common/btn_nav-open.png);
}

.l-header-fix {
  display: none;
}

@media screen and (min-width: 800px) {
  .l-header-fix {
    display: block;
    position: fixed;
    top: -80px;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: top 0.3s;
  }
  .l-header-fix.is-show {
    top: 0;
  }
}

.l-header-fix__inner {
  padding: 0 10px;
  max-width: 1000px;
  margin: 0 auto;
}

.l-header-fix__inner:after {
  content: '';
  display: block;
  clear: both;
}

.l-header-fix__logo {
  display: block;
  float: left;
  width: 36%;
  height: 70px;
  line-height: 70px;
  padding: 0 0 0 170px;
  background-image: url(/_assets/img/common/logo-fixed.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 160px;
}

.l-header-fix__menu {
  float: right;
  max-width: 610px;
  width: 63%;
  height: 70px;
}

.l-header-fix-main {
  width: 54%;
  height: 70px;
  float: left;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
}

.l-header-fix-main__item {
  display: table-cell;
  border-right: 1px solid #d4d2d2;
  border-left: 1px solid #d4d2d2;
  vertical-align: top;
}

.l-header-fix-main__item:hover {
  background-color: #f1f1f1;
}

.l-header-fix-main__item:hover > .l-header-fix-main__list {
  display: block;
  height: 200px;
  opacity: 1;
  transition: opacity 0.6s;
  z-index: 2;
}

.l-header-fix-main__ttl {
  display: block;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s;
}

.l-header-fix-main__ttl > span {
  display: block;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.6rem;
  line-height: 23px;
  width: 23px;
}

.l-header-fix-main__ttl > span span {
  font-size: 2.3rem;
}

.l-header-fix-main__list {
  display: block !important;
  margin: 0;
  background-image: url(/_assets/img/common/bg_nav.png);
  width: 100%;
  height: 200px;
  height: 0;
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 0;
  opacity: 0;
  transition: opacity 0.2s, height 0s 0.2s;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .l-header-fix-main__list .l-header-fix-main__link:last-child {
    border-right: 1px solid #62676e;
  }
}

@media screen and (min-width: 800px) {
  .l-header-fix-main__list.is-pray {
    padding-right: 130px;
  }
}

@media screen and (min-width: 800px) {
  .l-header-fix-main__list.is-know {
    padding-left: 90px;
  }
}

@media screen and (min-width: 800px) {
  .l-header-fix-main__list.is-see {
    padding: 0 0 0 310px;
  }
}

.l-header-fix-main__link {
  display: inline-block;
  vertical-align: top;
  height: 200px;
  border-left: 1px solid #62676e;
}

.l-header-fix-main__txt {
  display: block;
  position: relative;
  line-height: 50px;
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 800px) {
  .l-header-fix-main__txt {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    width: 50px;
    line-height: 50px;
    transition: background-color 0.4s;
    text-align: left;
    height: 100%;
    padding-top: 35px;
  }
  .l-header-fix-main__txt:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.l-header-fix-sub {
  width: 27%;
  float: right;
  display: table;
  float: right;
  width: 40%;
  height: 70px;
}

.l-header-fix-sub__item {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.l-header-fix-sub__item:first-child {
  padding: 0 10px 0 0;
}

.l-header-fix-sub__item:last-child {
  width: 120px;
}

.l-header-fix-sub__link {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  padding: 0 0 0 27px;
}

.l-header-fix-sub__link:hover {
  text-decoration: underline;
}

.l-header-fix-sub__link.is-access {
  background-image: url(/_assets/img/common/icon_access2.png);
}

.l-header-fix-sub__link.is-apply {
  background-image: url(/_assets/img/common/icon_apply2.png);
}

.l-nav {
  display: none;
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 65px);
  background-image: url(/_assets/img/common/bg_nav.png);
  background-size: 100%;
  color: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 80px;
}

@media screen and (min-width: 800px) {
  .l-nav {
    display: block;
    background: none;
    color: inherit;
    position: static;
    padding: 0;
    overflow: hidden;
    float: right;
    max-width: 610px;
    width: 63%;
    height: 130px;
  }
  .l-nav:after {
    content: '';
    display: block;
    clear: both;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-main {
    width: 54%;
    height: 130px;
    float: left;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-main__item {
    display: table-cell;
    height: 130px;
    border-right: 1px solid #d4d2d2;
    border-left: 1px solid #d4d2d2;
  }
  .l-nav-main__item:hover .l-nav-main__link {
    background-color: #ececec;
  }
  .l-nav-main__item:hover > .l-nav-sub {
    display: block;
    height: 200px;
    opacity: 1;
    transition: opacity 0.6s;
    z-index: 2;
  }
}

.l-nav-main__item:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(215, 217, 216, 0.25);
}

@media screen and (min-width: 800px) {
  .l-nav-main__item:after {
    display: none;
  }
}

.l-nav-main__link {
  display: block;
  position: relative;
  line-height: 65px;
  text-align: center;
  font-size: 2.4rem;
}

@media screen and (min-width: 800px) {
  .l-nav-main__link {
    width: 100%;
    height: 130px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.4s;
  }
}

.l-nav-main__link:after {
  content: '';
  display: block;
  width: 17px;
  height: 16px;
  background-image: url(/_assets/img/common/icon_arrow-down.png);
  background-size: 100%;
  position: absolute;
  top: 25px;
  right: 20px;
}

@media screen and (min-width: 800px) {
  .l-nav-main__link:after {
    display: none;
  }
}

.l-nav-main__link.is-open:after {
  background-image: url(/_assets/img/common/icon_arrow-up.png);
}

@media screen and (min-width: 800px) {
  .l-nav-main__link > span {
    display: block;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    width: 26px;
    font-size: 1.8rem;
    vertical-align: baseline;
  }
  .l-nav-main__link > span span {
    font-size: 2.6rem;
  }
}

.l-nav-sub {
  display: none;
  margin: 0 0 25px;
}

@media screen and (min-width: 800px) {
  .l-nav-sub {
    display: block !important;
    margin: 0;
    background-image: url(/_assets/img/common/bg_nav.png);
    width: 100%;
    height: 200px;
    height: 0;
    position: fixed;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 0;
    opacity: 0;
    transition: opacity 0.2s, height 0s 0.2s;
    overflow: hidden;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-sub.is-pray {
    padding-right: 130px;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-sub.is-know {
    padding-left: 90px;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-sub.is-see {
    padding: 0 0 0 310px;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-sub .l-nav-sub__item:last-child {
    border-right: 1px solid #62676e;
  }
}

@media screen and (min-width: 800px) {
  .l-nav-sub__item {
    display: inline-block;
    vertical-align: top;
    height: 200px;
    border-left: 1px solid #62676e;
  }
}

.l-nav-sub__link {
  display: block;
  position: relative;
  line-height: 50px;
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 800px) {
  .l-nav-sub__link {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    width: 50px;
    line-height: 50px;
    padding-top: 35px;
    height: 100%;
    text-align: left;
    transition: background-color 0.4s;
  }
  .l-nav-sub__link:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.l-nav-menu {
  margin: 44px 0 30px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .l-nav-menu {
    float: right;
    width: 20%;
    text-align: left;
    margin: 40px 0 0;
  }
}

.l-nav-menu__item {
  display: inline-block;
}

@media screen and (min-width: 800px) {
  .l-nav-menu__item {
    display: block;
  }
}

.l-nav-menu__item + .l-nav-menu__item {
  margin: 0 0 0 30px;
}

@media screen and (min-width: 800px) {
  .l-nav-menu__item + .l-nav-menu__item {
    margin: 15px 0 0;
  }
}

.l-nav-menu__link {
  display: inline-block;
  padding: 0 0 0 30px;
  line-height: 22px;
  font-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 22px;
}

@media screen and (min-width: 800px) {
  .l-nav-menu__link {
    font-size: 1.4rem;
    background-size: 20px;
    padding: 0 0 0 27px;
  }
  .l-nav-menu__link:hover {
    text-decoration: underline;
  }
}

.l-nav-menu__link.is-access {
  background-image: url(/_assets/img/common/icon_access.png);
}

@media screen and (min-width: 800px) {
  .l-nav-menu__link.is-access {
    background-image: url(/_assets/img/common/icon_access2.png);
  }
}

.l-nav-menu__link.is-apply {
  background-image: url(/_assets/img/common/icon_apply.png);
}

@media screen and (min-width: 800px) {
  .l-nav-menu__link.is-apply {
    background-image: url(/_assets/img/common/icon_apply2.png);
  }
}

.l-nav-menu-sub {
  text-align: center;
}

@media screen and (min-width: 800px) {
  .l-nav-menu-sub {
    display: none;
  }
}

.l-nav-menu-sub__item {
  display: inline-block;
  font-size: 0;
}

.l-nav-menu-sub__item:after {
  content: '/';
  display: inline-block;
  padding: 0 5px 0 10px;
  font-size: 1.4rem;
}

.l-nav-menu-sub__item:last-child:after {
  display: none;
}

.l-nav-menu-sub__link {
  font-size: 1.4rem;
}

.l-main {
  width: 100%;
  overflow: hidden;
  padding: 45px 0 0;
}

@media screen and (min-width: 800px) {
  .l-main {
    padding: 0;
  }
}

.l-footer {
  border-top: 1px solid #e2e1e1;
  background-color: rgba(226, 225, 225, 0.3);
  text-align: center;
  padding: 40px 0 55px;
  margin: 40px 0 0;
}

@media screen and (min-width: 800px) {
  .l-footer {
    text-align: left;
    padding: 80px 0 85px;
  }
}

@media screen and (min-width: 800px) {
  .l-footer__inner {
    padding: 0 10px;
    max-width: 1000px;
    margin: 0 auto;
  }
  .l-footer__inner:after {
    content: '';
    display: block;
    clear: both;
  }
}

@media screen and (min-width: 800px) {
  .l-footer-main {
    max-width: 250px;
    width: 31%;
    float: left;
  }
}

.l-footer-main__name {
  font-size: 1rem;
}

@media screen and (min-width: 800px) {
  .l-footer-main__name {
    text-align: center;
    font-size: 1.4rem;
  }
}

.l-footer-main__name img {
  display: inline-block;
  width: 140px;
  margin: 10px 0 5px;
}

@media screen and (min-width: 800px) {
  .l-footer-main__name img {
    width: 186px;
    margin: 15px 0 10px;
  }
}

.l-footer-main__name span {
  font-size: 1.5rem;
}

@media screen and (min-width: 800px) {
  .l-footer-main__name span {
    font-size: 1.6rem;
  }
}

.l-footer-main__address {
  margin: 18px 0 30px;
  font-size: 1.2rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .l-footer-main__address {
    margin: 25px 0 0;
    line-height: 1.8;
  }
}

.l-footer-main__address span {
  margin: 0 10px 0 0;
}

@media screen and (min-width: 800px) {
  .l-footer-main__address span {
    display: block;
  }
}

.l-footer-sitemap {
  display: none;
}

@media screen and (min-width: 800px) {
  .l-footer-sitemap {
    display: block;
    max-width: 660px;
    width: 68%;
    float: right;
  }
  .l-footer-sitemap:after {
    content: '';
    display: block;
    clear: both;
  }
}

.l-footer-sitemap__column {
  width: 25%;
  float: left;
}

.l-footer-sitemap__ttl {
  font-size: 2.2rem;
  margin: 0 0 20px;
}

@media screen and (min-width: 800px) {
  .l-footer-sitemap__ttl:hover {
    text-decoration: underline;
  }
}

.l-footer-sitemap__item {
  margin: 15px 0 0;
}

.l-footer-sitemap__link {
  font-size: 1.3rem;
}

@media screen and (min-width: 800px) {
  .l-footer-sitemap__link:hover {
    text-decoration: underline;
  }
}

.l-footer-sitemap-menu {
  margin: 0 0 30px;
}

.l-footer-sitemap-menu__item {
  margin: 0 0 10px;
}

.l-footer-sitemap-menu__link {
  display: inline-block;
  height: 20px;
  font-size: 1.4rem;
  background-repeat: no-repeat;
  background-size: 20px;
  line-height: 20px;
  padding: 0 0 0 30px;
}

@media screen and (min-width: 800px) {
  .l-footer-sitemap-menu__link:hover {
    text-decoration: underline;
  }
}

.l-footer-sitemap-menu__link.is-access {
  background-image: url(/_assets/img/common/icon_access2.png);
}

.l-footer-sitemap-menu__link.is-apply {
  background-image: url(/_assets/img/common/icon_apply2.png);
}

.l-footer-bottom {
  background-image: url(/_assets/img/common/footer_logo.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 40px;
  padding: 50px 0 0;
}

@media screen and (min-width: 800px) {
  .l-footer-bottom {
    clear: both;
    padding: 150px 0 0;
    text-align: center;
    background-position: center 80px;
    background-size: 54px;
  }
}

.c-block--xl {
  margin-bottom: 80px;
}

@media screen and (min-width: 800px) {
  .c-block--xl {
    margin-bottom: 120px;
  }
}

.c-block--l {
  margin-bottom: 65px;
}

@media screen and (min-width: 800px) {
  .c-block--l {
    margin-bottom: 100px;
  }
}

.c-block--m {
  margin-bottom: 65px;
}

@media screen and (min-width: 800px) {
  .c-block--m {
    margin-bottom: 80px;
  }
}

.c-block--s {
  margin-bottom: 35px;
}

@media screen and (min-width: 800px) {
  .c-block--s {
    margin-bottom: 50px;
  }
}

.c-block--xs {
  margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
  .c-block--xs {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--2 .c-grid__block {
    width: 50%;
    padding: 0 20px;
  }
}

.c-grid--2 .c-grid__block + .c-grid__block {
  margin: 30px 0 0;
}

@media screen and (min-width: 800px) {
  .c-grid--2 .c-grid__block + .c-grid__block {
    margin: 0;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--3 .c-grid__block {
    width: calc(100% / 3);
    padding: 0 20px;
  }
}

.c-grid--3 .c-grid__block + .c-grid__block {
  margin: 30px 0 0;
}

@media screen and (min-width: 800px) {
  .c-grid--3 .c-grid__block + .c-grid__block {
    margin: 0;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--2-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.c-grid--2-1 .c-grid__block:first-child {
  margin: 0 0 30px;
}

@media screen and (min-width: 800px) {
  .c-grid--2-1 .c-grid__block:first-child {
    width: 73%;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--2-1 .c-grid__block:last-child {
    width: 27%;
    padding: 0 0 0 40px;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--1-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.c-grid--1-2 .c-grid__block:first-child {
  margin: 0 0 30px;
}

@media screen and (min-width: 800px) {
  .c-grid--1-2 .c-grid__block:first-child {
    order: 2;
    width: 73%;
  }
}

@media screen and (min-width: 800px) {
  .c-grid--1-2 .c-grid__block:last-child {
    order: 1;
    width: 27%;
    padding: 0 40px 0 0;
  }
}

.c-heading__icon {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-color: #474240;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 27px;
  position: relative;
  top: -2px;
  margin: 0 10px 0 0;
  vertical-align: middle;
  font-weight: normal;
}

@media screen and (min-width: 800px) {
  .c-heading__icon {
    top: -4px;
  }
}

.c-heading__icon.is-car, .c-heading__icon.is-train {
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
}

.c-heading__icon.is-car {
  background-image: url(/_assets/img/common/icon_car.png);
}

.c-heading__icon.is-train {
  background-image: url(/_assets/img/common/icon_train.png);
}

.c-heading__label {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  border: 1px solid #787878;
  position: relative;
  top: 0px;
  width: 72px;
  text-align: center;
  padding: 2px 0;
  margin: 0 0 0 10px;
  font-weight: normal;
}

@media screen and (min-width: 800px) {
  .c-heading__label {
    top: -2px;
  }
}

.c-heading__label + .c-heading__label {
  margin: 0 0 0 -1px;
}

.c-heading--lv1 {
  overflow: hidden;
  font-size: 2.1rem;
  text-align: center;
  position: relative;
  padding: 0 40px;
  margin: 0 -30px;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .c-heading--lv1 {
    font-size: 2.8rem;
  }
}

.c-heading--lv1:before, .c-heading--lv1:after {
  content: '';
  display: block;
  width: 31px;
  height: 3px;
  background-image: url(/_assets/img/common/line.png);
  background-repeat: no-repeat;
  position: absolute;
  background-size: 43px;
  top: 50%;
  margin: -2px 0 0;
}

@media screen and (min-width: 800px) {
  .c-heading--lv1:before, .c-heading--lv1:after {
    display: none;
  }
}

.c-heading--lv1:before {
  left: 0;
  background-position: right center;
}

.c-heading--lv1:after {
  right: 0;
  background-position: left center;
}

@media screen and (min-width: 800px) {
  .c-heading--lv1 span {
    display: inline-block;
    position: relative;
    padding: 0 70px;
  }
}

.c-heading--lv1 span:before, .c-heading--lv1 span:after {
  content: '';
  display: none;
  width: 43px;
  height: 3px;
  background-image: url(/_assets/img/common/line.png);
  background-repeat: no-repeat;
  position: absolute;
  background-size: 43px;
  top: 50%;
  margin: -1px 0 0;
}

@media screen and (min-width: 800px) {
  .c-heading--lv1 span:before, .c-heading--lv1 span:after {
    display: block;
  }
}

.c-heading--lv1 span:before {
  left: 0;
  background-position: right center;
}

.c-heading--lv1 span:after {
  right: 0;
  background-position: left center;
}

.c-heading--lv2 {
  font-size: 1.8rem;
  text-align: center;
  padding: 0 0 15px;
  background-image: url(/_assets/img/common/line.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 43px;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .c-heading--lv2 {
    font-size: 2rem;
    padding: 0 0 25px;
  }
}

.c-heading--lv2__icon {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-color: #474240;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 27px;
  position: relative;
  top: -2px;
  margin: 0 10px 0 0;
}

@media screen and (min-width: 800px) {
  .c-heading--lv2__icon {
    top: -4px;
  }
}

.c-heading--lv2__label {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  border: 1px solid #787878;
  position: relative;
  top: 0px;
  width: 72px;
  text-align: center;
  padding: 2px 0;
  margin: 0 0 0 10px;
}

@media screen and (min-width: 800px) {
  .c-heading--lv2__label {
    top: -2px;
  }
}

.c-heading--lv2__label + .c-heading--lv4__label {
  margin: 0 0 0 -1px;
}

.c-heading--lv3 {
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .c-heading--lv3 {
    font-size: 2rem;
  }
}

.c-heading--lv3__icon {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-color: #474240;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 27px;
  position: relative;
  top: -2px;
  margin: 0 10px 0 0;
}

@media screen and (min-width: 800px) {
  .c-heading--lv3__icon {
    top: -4px;
  }
}

.c-heading--lv3__label {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  border: 1px solid #787878;
  position: relative;
  top: 0px;
  width: 72px;
  text-align: center;
  padding: 2px 0;
  margin: 0 0 0 10px;
}

@media screen and (min-width: 800px) {
  .c-heading--lv3__label {
    top: -2px;
  }
}

.c-heading--lv3__label + .c-heading--lv3__label {
  border-left: none;
  margin: 0;
}

.c-txt, .article p {
  font-size: 1.4rem;
  line-height: 1.8;
}

@media screen and (min-width: 800px) {
  .c-txt, .article p {
    font-size: 1.6rem;
    line-height: 2;
  }
}

.c-txt__link {
  text-decoration: underline;
  padding: 0 5px 0 0;
}

.c-txt__link[target="_blank"] {
  padding: 0 15px 0 0;
  background-image: url(/_assets/img/common/icon_blank.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 12px;
  margin: 0 5px 0 0;
}

@media screen and (min-width: 800px) {
  .c-txt__link:hover {
    text-decoration: none;
  }
}

.c-img > img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.c-img--full {
  margin: 0 -30px;
}

@media screen and (min-width: 800px) {
  .c-img--full {
    margin: 0 auto;
  }
}

.c-img--full > img {
  display: block;
  width: 100%;
  height: auto;
}

.c-img--wide {
  width: 100%;
  max-width: none;
}

@media screen and (min-width: 800px) {
  .c-img-txt {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}

.c-img-txt__img {
  margin: 0 0 30px;
}

@media screen and (min-width: 800px) {
  .c-img-txt__img {
    width: 58.16327%;
    margin: 0;
  }
}

.c-img-txt__img img {
  display: block;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .c-img-txt__img img {
    margin: 0 0 5px;
  }
}

.c-img-txt__img span {
  color: #777;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .c-img-txt__img span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 800px) {
  .c-img-txt__txt {
    width: 41.83673%;
    padding: 0 0 0 40px;
  }
}

@media screen and (min-width: 800px) {
  .c-img-txt.is-reverse .c-img-txt__img {
    order: 2;
  }
}

@media screen and (min-width: 800px) {
  .c-img-txt.is-reverse .c-img-txt__txt {
    order: 1;
    padding: 0 40px 0 0;
  }
}

.c-list--dot, .c-flower__list, .article ul {
  padding: 0 0 0 20px;
}

.c-list--dot li, .c-flower__list li, .article ul li {
  list-style-type: square;
  font-size: 1.4rem;
  line-height: 1.8;
}

@media screen and (min-width: 800px) {
  .c-list--dot li, .c-flower__list li, .article ul li {
    font-size: 1.6rem;
    line-height: 2;
  }
}

.c-list--dot li + li, .c-flower__list li + li, .article ul li + li {
  margin: 20px 0 0;
}

.c-list--num {
  padding: 0 0 0 20px;
}

.c-list--num li {
  list-style-type: decimal;
  font-size: 1.4rem;
  line-height: 1.8;
}

@media screen and (min-width: 800px) {
  .c-list--num li {
    font-size: 1.6rem;
    line-height: 2;
  }
}

.c-list--num li + li {
  margin: 20px 0 0;
}

.c-list--link li {
  font-size: 1.4rem;
  line-height: 1.8;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-position: left -45px center;
  background-repeat: no-repeat;
  background-size: 71px;
  padding: 0 0 0 35px;
}

@media screen and (min-width: 800px) {
  .c-list--link li {
    font-size: 1.6rem;
  }
}

.c-list--link li + li {
  margin: 16px 0 0;
}

@media screen and (min-width: 800px) {
  .c-list--link li + li {
    margin: 10px 0 0;
  }
}

.c-list--link a[target="_blank"] {
  padding: 0 15px 0 0;
  background-image: url(/_assets/img/common/icon_blank.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 12px;
  margin: 0 5px 0 0;
}

.c-list--link a:visited {
  color: #777777;
}

@media screen and (min-width: 800px) {
  .c-list--link a:hover {
    text-decoration: underline;
  }
}

.c-list--button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 -10px;
}

@media screen and (min-width: 800px) {
  .c-list--button {
    justify-content: center;
    margin: 0 0 -2px;
  }
}

.c-list--button li {
  display: inline-block;
  width: 50%;
  padding: 0 5px 10px 0;
  font-size: 0;
}

@media screen and (min-width: 800px) {
  .c-list--button li {
    padding: 0 0 2px;
    width: calc(100% / 6);
  }
}

.c-list--button li:nth-of-type(even) {
  padding: 0 0 10px 5px;
}

@media screen and (min-width: 800px) {
  .c-list--button li:nth-of-type(even) {
    padding: 0 5px 2px;
  }
}

.c-list--button a {
  display: block;
  background-color: rgba(55, 50, 47, 0.9);
  color: #fff;
  text-align: center;
  padding: 15px 0;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.2;
}

@media screen and (min-width: 800px) {
  .c-list--button a {
    width: 100%;
    margin: 0 auto;
    padding: 23px 0;
    transition: background-color 0.4s;
    font-size: 1.6rem;
  }
  .c-list--button a:hover {
    background-color: #37322f;
  }
}

.c-list--backnumber {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;
}

@media screen and (min-width: 800px) {
  .c-list--backnumber {
    margin: 0 -15px;
    justify-content: flex-start;
  }
}

.c-list--backnumber li {
  width: 50%;
  padding: 0 10px;
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .c-list--backnumber li {
    width: 16.66663%;
    padding: 0 15px;
  }
}

.c-list--backnumber li .c-flower {
  display: none;
}

.c-list--backnumber a {
  display: block;
}

.c-list--backnumber img {
  display: block;
  max-width: none;
  width: 100%;
}

.c-list--backnumber p {
  font-size: 1.2rem;
  text-align: center;
  padding: 10px 0 0;
}

@media screen and (min-width: 800px) {
  .c-list--backnumber p {
    padding: 15px 0 0;
    font-size: 1.4rem;
  }
}

.c-list--news {
  margin: -5px 0 15px;
}

@media screen and (min-width: 800px) {
  .c-list--news {
    margin: 0 0 -20px;
  }
  .c-list--news:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-list--news__date {
  margin: 0 0 2px;
  font-size: 1.4rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .c-list--news__date {
    width: 15.3%;
    float: left;
    clear: both;
    font-size: 1.6rem;
    margin: 0 0 20px;
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  .c-list--news__date:after {
    content: '';
    display: block;
    width: 24px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 18px;
    margin: -7px 0 0;
    background-image: url(/_assets/img/common/icon_arrow-right.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 72px;
  }
}

.c-list--news__txt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 800px) {
  .c-list--news__txt {
    width: 84%;
    float: right;
    margin: 0 0 20px;
  }
}

.c-list--news__txt + .top-news__date {
  margin-top: 15px;
}

@media screen and (min-width: 800px) {
  .c-list--news__txt + .top-news__date {
    margin-top: 0;
  }
}

.c-list--news__link {
  font-size: 1.4rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .c-list--news__link {
    font-size: 1.6rem;
  }
  .c-list--news__link:hover {
    text-decoration: underline;
  }
}

.c-list--news__more {
  text-align: right;
  font-size: 1.4rem;
}

@media screen and (min-width: 800px) {
  .c-list--news__more {
    padding: 10px 0 0;
  }
}

.c-list--news__more a {
  display: inline-block;
  padding: 0 0 10px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 72px;
}

@media screen and (min-width: 800px) {
  .c-list--news__more a {
    background-size: 72px;
    background-position: 0 bottom;
  }
  .c-list--news__more a:hover {
    animation-duration: 0.4s;
    animation-name: top-news__more;
  }
}

.c-list--news-archive {
  border-top: 1px solid #e8e7e7;
}

@media screen and (min-width: 800px) {
  .c-list--news-archive {
    border-top: none;
    text-align: center;
  }
}

.c-list--news-archive li {
  border-bottom: 1px solid #e8e7e7;
}

@media screen and (min-width: 800px) {
  .c-list--news-archive li {
    display: inline-block;
    border-bottom: none;
    border-right: 1px solid #e8e7e7;
  }
  .c-list--news-archive li:last-child {
    border-right: none;
  }
}

.c-list--news-archive a {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  padding: 15px 30px;
}

.c-list--news-archive a.is-current {
  text-decoration: underline;
}

@media screen and (min-width: 800px) {
  .c-list--news-archive a {
    padding: 8px 30px 8px 30px;
  }
  .c-list--news-archive a:hover {
    text-decoration: underline;
  }
}

@keyframes c-more-link {
  0% {
    background-position: right 72px bottom;
  }
  100% {
    background-position: right 0 bottom;
  }
}

.c-more-link {
  text-align: right;
  font-size: 1.4rem;
}

@media screen and (min-width: 800px) {
  .c-more-link {
    padding: 10px 0 0;
  }
}

.c-more-link a {
  display: inline-block;
  padding: 0 0 10px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 72px;
}

@media screen and (min-width: 800px) {
  .c-more-link a {
    background-size: 72px;
    background-position: right 0 bottom;
  }
  .c-more-link a:hover {
    animation-duration: 0.4s;
    animation-name: c-more-link;
  }
}

.c-button {
  margin: 0;
}

.c-button:after {
  content: '';
  display: block;
  clear: both;
}

@media screen and (min-width: 800px) {
  .c-button {
    margin: 0 -20px;
  }
}

.c-button a {
  display: inline-block;
  background-color: rgba(55, 50, 47, 0.9);
  color: #fff;
  text-align: center;
  padding: 15px 45px 15px 15px;
  position: relative;
  min-width: 100%;
  margin: 2px 0;
}

@media screen and (min-width: 800px) {
  .c-button a {
    min-width: 310px;
    margin: 0 20px;
    padding: 20px 45px;
    transition: background-color 0.4s;
  }
  .c-button a:hover {
    background-color: #37322f;
  }
  .c-button a:hover:after {
    right: 10px;
  }
}

.c-button a:after {
  content: '';
  width: 24px;
  height: 6px;
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 71px;
  margin: -3px 0 0;
  transition: right 0.3s;
}

.c-button a.is-pdf {
  background-image: url(/_assets/img/common/icon_pdf.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;
}

.c-button a.is-pdf:after {
  display: none;
}

.c-anchor {
  border-top: 1px solid #e8e7e7;
}

@media screen and (min-width: 800px) {
  .c-anchor {
    border-top: none;
    text-align: center;
  }
}

.c-anchor li {
  border-bottom: 1px solid #e8e7e7;
}

@media screen and (min-width: 800px) {
  .c-anchor li {
    display: inline-block;
    border-bottom: none;
    border-right: 1px solid #e8e7e7;
  }
  .c-anchor li:last-child {
    border-right: none;
  }
}

.c-anchor a {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  padding: 15px 30px;
  background-image: url(/_assets/img/common/icon_arrow-down.png);
  background-repeat: no-repeat;
  background-position: 18px center;
  background-size: 12px;
}

@media screen and (min-width: 800px) {
  .c-anchor a {
    padding: 8px 30px 8px 55px;
    background-position: 30px center;
  }
  .c-anchor a:hover {
    text-decoration: underline;
  }
}

.c-breadcrumb {
  display: none;
}

@media screen and (min-width: 800px) {
  .c-breadcrumb {
    display: block;
    padding: 15px 0;
    margin: 0 0 50px;
  }
}

.c-breadcrumb li {
  display: inline-block;
  font-size: 1.2rem;
}

.c-breadcrumb li:first-child a:before,
.c-breadcrumb li:first-child span:before {
  display: none;
}

.c-breadcrumb a {
  text-decoration: underline;
}

.c-breadcrumb a:hover {
  text-decoration: none;
}

.c-breadcrumb a:before,
.c-breadcrumb span:before {
  content: '>';
  display: inline-block;
  height: 1em;
  padding: 0 5px 0 3px;
  text-align: center;
}

.c-table table, .article table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.2rem;
  border: 1px solid #dddddd;
}

@media screen and (min-width: 800px) {
  .c-table table, .article table table {
    font-size: 1.4rem;
  }
}

.c-table .is-left, .article table .is-left {
  text-align: left;
}

.c-table .is-right, .article table .is-right {
  text-align: right;
}

.c-table th, .article table th,
.c-table td, .article table td {
  text-align: center;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-left: 1px dotted #dddddd;
  border-right: 1px dotted #dddddd;
  padding: 10px;
  vertical-align: middle;
}

@media screen and (min-width: 800px) {
  .c-table th, .article table th,
  .c-table td, .article table td {
    padding: 15px 20px;
  }
}

.c-table th, .article table th {
  font-weight: 400;
  background-color: #f1f1f1;
}

.c-table.is-scroll, .article table.is-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 800px) {
  .c-table.is-scroll, .article table.is-scroll {
    overflow: hidden;
  }
}

.c-table.is-scroll table, .article table.is-scroll table {
  width: 980px;
}

@media screen and (min-width: 800px) {
  .c-table.is-scroll table, .article table.is-scroll table {
    width: 100%;
  }
}

.c-column {
  border: 1px solid #d7d5d5;
  background-color: #fff;
  position: relative;
  padding: 25px;
}

@media screen and (min-width: 800px) {
  .c-column {
    padding: 50px 60px;
  }
}

.c-column:before, .c-column:after {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
}

.c-column:before {
  top: -7px;
  left: -7px;
  background-image: url(/_assets/img/common/bg_column-top.png);
}

.c-column:after {
  bottom: -7px;
  right: -7px;
  background-image: url(/_assets/img/common/bg_column-bottom.png);
}

.c-column__lead {
  font-size: 1.2rem;
  color: #999999;
  text-align: center;
  border-bottom: 1px solid #d7d5d5;
  line-height: 1;
  padding: 0 0 15px;
}

@media screen and (min-width: 800px) {
  .c-column__lead {
    font-size: 1.3rem;
    display: inline-block;
    line-height: 30px;
    border-bottom: none;
    border-right: 1px solid #d7d5d5;
    padding: 0 25px 0 0;
  }
}

.c-column__ttl {
  font-size: 1.6rem;
  text-align: center;
  padding: 15px 0 20px;
}

@media screen and (min-width: 800px) {
  .c-column__ttl {
    font-size: 1.8rem;
    display: inline-block;
    line-height: 30px;
    padding: 0 0 0 25px;
    margin: 0 0 30px;
  }
}

.c-column__txt {
  font-size: 1.2rem;
  line-height: 1.8;
}

@media screen and (min-width: 800px) {
  .c-column__txt {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.c-migration {
  margin: 0 -30px;
  border-top: 1px solid #e6e5e4;
}

@media screen and (min-width: 800px) {
  .c-migration {
    margin: 0;
  }
}

.c-migration__item {
  height: 90px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  padding: 0 30px;
  border-bottom: 1px solid #e6e5e4;
}

@media screen and (min-width: 800px) {
  .c-migration__item {
    height: 120px;
    padding: 0;
    background: none !important;
  }
}

@media screen and (min-width: 800px) {
  .c-migration__item:nth-of-type(even) .c-migration__bg {
    background-position: left 50px center;
  }
}

.c-migration__link {
  display: block;
  height: 100%;
  background-image: url(/_assets/img/common/icon_arrow-right2.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 16px;
}

@media screen and (min-width: 800px) {
  .c-migration__link {
    position: relative;
    background: none;
  }
  .c-migration__link:hover:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(204, 204, 204, 0.1);
  }
  .c-migration__link:hover .c-migration__inner:after {
    right: -16px;
  }
}

.c-migration__inner {
  height: 100%;
}

@media screen and (min-width: 800px) {
  .c-migration__inner {
    height: 120px;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
  }
  .c-migration__inner:before {
    content: '';
    display: block;
    width: 1px;
    height: 60px;
    background-color: #e6e5e4;
    position: absolute;
    top: 50%;
    right: 51%;
    margin: -30px 0 0;
  }
  .c-migration__inner:after {
    content: '';
    display: block;
    width: 16px;
    height: 17px;
    position: absolute;
    top: 50%;
    right: 0;
    background-image: url(/_assets/img/common/icon_arrow-right2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    transition: right 0.4s;
    margin: -8px 0 0;
  }
}

.c-migration__bg {
  height: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  padding: 0 50px 0 180px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  .c-migration__bg {
    width: 100%;
    padding: 0;
    display: table;
  }
}

.c-migration__ttl {
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  margin: 0 0 5px;
}

@media screen and (min-width: 800px) {
  .c-migration__ttl {
    display: table-cell;
    width: 49%;
    padding: 0 30px 0 33%;
    margin: 0;
    vertical-align: middle;
    font-size: 2rem;
    line-height: 1.4;
    position: relative;
  }
}

.c-migration__txt {
  display: block;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .c-migration__txt {
    display: table-cell;
    width: 51%;
    font-size: 1.4rem;
    padding: 0 40px 0 30px;
    vertical-align: middle;
    position: relative;
  }
}

.c-contents {
  padding: 0 30px;
}

@media screen and (min-width: 800px) {
  .c-contents {
    padding: 0 22px;
    max-width: 1024px;
    margin: 0 auto;
  }
}

.c-contents--narrow {
  padding: 0 30px;
}

@media screen and (min-width: 800px) {
  .c-contents--narrow {
    padding: 0 22px;
    max-width: 804px;
    margin: 0 auto;
  }
}

.c-relation {
  border-top: 1px solid #e2e1e1;
  padding: 40px 30px 0;
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .c-relation {
    padding: 80px 0 0;
    margin: 0 0 80px;
  }
}

.c-relation__list {
  padding: 25px 0 0;
}

@media screen and (min-width: 800px) {
  .c-relation__list {
    padding: 50px 22px 0;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-relation__item {
  border-top: 1px solid #e2e1e1;
}

@media screen and (min-width: 800px) {
  .c-relation__item {
    width: calc(100% / 3);
    border-top: none;
    border-left: 1px solid #e2e1e1;
  }
}

.c-relation__item:last-child {
  border-bottom: 1px solid #e2e1e1;
}

@media screen and (min-width: 800px) {
  .c-relation__item:last-child {
    border-bottom: none;
    border-right: 1px solid #e2e1e1;
  }
}

.c-relation__link {
  display: block;
  min-height: 110px;
  position: relative;
  padding: 10px 10px 10px 110px;
}

.c-relation__link:after {
  content: '';
  display: block;
  width: 24px;
  height: 6px;
  position: absolute;
  bottom: 10px;
  right: 28px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 72px;
  transition: right 0.4s;
}

@media screen and (min-width: 800px) {
  .c-relation__link {
    position: relative;
    padding: 10px 28px 10px 138px;
    transition: background-color 0.4s;
  }
  .c-relation__link:hover {
    background-color: rgba(204, 204, 204, 0.1);
  }
  .c-relation__link:hover:after {
    right: 20px;
  }
}

.c-relation__img {
  display: block;
  width: 90px;
  height: 90px;
  position: absolute;
  top: 10px;
  left: 10px;
}

@media screen and (min-width: 800px) {
  .c-relation__img {
    display: block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    left: 28px;
  }
}

.c-relation__ttl {
  font-size: 1.6rem;
  margin: 0 0 10px;
}

@media screen and (min-width: 800px) {
  .c-relation__ttl {
    padding: 3px 0 0;
    font-size: 1.5rem;
  }
}

.c-relation__txt {
  font-size: 1.4rem;
}

@media screen and (min-width: 800px) {
  .c-relation__txt {
    font-size: 1.3rem;
    line-height: 1.6;
  }
}

.c-carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 800px) {
  .c-carousel {
    display: block;
    margin: 0 -20px;
  }
}

.c-carousel__block {
  width: 47%;
  margin: 0 0 20px;
}

@media screen and (min-width: 800px) {
  .c-carousel__block {
    width: auto;
    margin: 0;
    display: block;
    height: auto;
    padding: 0 20px;
    outline: none;
  }
  .c-carousel__block[aria-hidden="true"] {
    opacity: 0.3;
  }
}

.c-carousel .slick-list {
  overflow: visible;
}

.c-carousel .slick-dots {
  margin: 15px 0 0;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .c-carousel .slick-dots {
    margin: 25px 0 0;
  }
}

.c-carousel .slick-dots li {
  display: inline-block;
  font-size: 0;
}

.c-carousel .slick-dots li.slick-active button {
  background-color: #332e2b;
  border: 1px solid #332e2b;
}

.c-carousel .slick-dots button {
  display: inline-block;
  width: 8px;
  height: 8px;
  font-size: 0;
  background: none;
  border: none;
  border: 1px solid #c9c7c6;
  border-radius: 50%;
  padding: 0;
  margin: 0 6px;
}

@media screen and (min-width: 800px) {
  .c-carousel .slick-dots button {
    width: 10px;
    height: 10px;
    margin: 0 7px;
    cursor: pointer;
    transition: background-color 0.4s;
  }
  .c-carousel .slick-dots button:hover {
    background-color: rgba(201, 199, 198, 0.3);
  }
}

.c-carousel .slick-arrow {
  display: none;
}

@media screen and (min-width: 800px) {
  .c-carousel .slick-arrow {
    display: block;
    position: absolute;
    top: 40%;
    z-index: 10;
    width: 14px;
    height: 16px;
    font-size: 0;
    background: none;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.c-carousel .slick-prev {
  left: -100px;
  background-image: url(/_assets/img/common/icon_arrow-left.png);
}

.c-carousel .slick-next {
  right: -100px;
  background-image: url(/_assets/img/common/icon_arrow-right2.png);
}

.c-flower {
  color: #fff;
  background-position: center;
  background-size: cover;
  background-image: url(/_assets/img/top/menu_bg.png);
}

.c-flower__inner {
  padding: 0 30px;
}

.c-flower__inner:after {
  content: '';
  display: block;
  clear: both;
}

@media screen and (min-width: 800px) {
  .c-flower__inner {
    width: 800px;
    height: 480px;
    display: table;
    margin: 0 auto;
    padding: 0;
  }
}

.c-flower__img {
  padding: 30px 10px 30px 0;
}

@media screen and (min-width: 800px) {
  .c-flower__img {
    float: none;
    display: table-cell;
    width: 360px;
    padding: 0;
  }
}

.c-flower__img img {
  width: auto;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 800px) {
  .c-flower__img img {
    max-width: none;
    width: 100%;
  }
}

.c-flower__info {
  padding: 30px 10px;
}

@media screen and (min-width: 800px) {
  .c-flower__info {
    float: none;
    display: table-cell;
    width: 440px;
    padding: 0;
    padding: 0 0 0 60px;
    vertical-align: middle;
  }
}

.c-flower__ttl {
  font-size: 1.6rem;
  margin: 0 0 20px;
}

@media screen and (min-width: 800px) {
  .c-flower__ttl {
    font-size: 2rem;
    margin: 0 0 40px;
  }
}

.c-flower__lead {
  font-size: 1.2rem;
  margin: 0 0 10px;
}

@media screen and (min-width: 800px) {
  .c-flower__lead {
    font-size: 1.6rem;
    margin: 0 0 20px;
  }
}

.c-flower__list:after {
  content: '';
  display: block;
  clear: both;
}

.c-flower__list li {
  width: 50%;
  padding-right: 20px;
  float: left;
}

.c-flower__list li + li {
  margin: 0;
}

.c-flower__list li:nth-of-type(odd) {
  clear: both;
}

.c-hr {
  box-shadow: none;
  padding: 0;
  border: none;
  border-top: 1px solid #d4d2d2;
  margin: 20px 0;
}

@media screen and (min-width: 800px) {
  .c-hr {
    margin: 40px 0;
  }
}

.c-backnumber-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
}

.c-backnumber-modal__inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 10;
  transform: translate(0, -50%);
  max-height: 100%;
  overflow: scroll;
  overflow-scrolling: touch;
}

@media screen and (min-width: 800px) {
  .c-backnumber-modal__inner {
    overflow: hidden;
    max-height: none;
    min-height: 480px;
  }
}

.c-backnumber-modal__close {
  display: block;
  width: 32px;
  height: 17px;
  background-image: url(/_assets/img/common/btn_nav-open.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 800px) {
  .c-backnumber-modal__close {
    top: 20px;
    right: 20px;
  }
}

.c-backnumber-modal__prev {
  display: block;
  width: 16px;
  height: 17px;
  background-image: url(/_assets/img/common/icon_arrow-left.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 50%;
  left: 10px;
  margin: -8px 0 0;
}

@media screen and (min-width: 800px) {
  .c-backnumber-modal__prev {
    left: 50%;
    margin: -8px 0 0 -490px;
  }
}

.c-backnumber-modal__next {
  display: block;
  width: 16px;
  height: 17px;
  background-image: url(/_assets/img/common/icon_arrow-right2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 50%;
  right: 10px;
  margin: -8px 0 0;
}

@media screen and (min-width: 800px) {
  .c-backnumber-modal__next {
    right: 50%;
    margin: -8px -490px 0 0;
  }
}

@media screen and (min-width: 800px) {
  .c-profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }
}

.c-profile__block {
  margin-bottom: 50px;
  position: relative;
}

@media screen and (min-width: 800px) {
  .c-profile__block {
    width: 50%;
    padding: 0 20px;
    margin-bottom: 90px;
  }
}

.c-profile__block:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: url("/_assets/img/common/line_profile.png") no-repeat left top;
}

@media screen and (min-width: 800px) {
  .c-profile__block:after {
    width: calc(100% - 40px);
    left: 20px;
  }
}

.c-profile__inner {
  display: table;
  height: 150px;
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  .c-profile__inner {
    margin-bottom: 40px;
    height: 200px;
  }
}

.c-profile__img {
  width: 34.0%;
  display: table-cell;
}

.c-profile__img img {
  min-width: 120px;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .c-profile__img img {
    min-width: 160px;
  }
}

.c-profile__info {
  display: table-cell;
  padding: 0;
  padding: 0 0 0 20px;
  vertical-align: top;
}

@media screen and (min-width: 800px) {
  .c-profile__info {
    padding: 0 0 0 32px;
  }
}

.c-profile__info__inner {
  display: table;
  height: 100%;
}

.c-profile__info__inner__row {
  display: table-row;
}

.c-profile__ttl {
  display: table-cell;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 1rem;
}

@media screen and (min-width: 800px) {
  .c-profile__ttl {
    font-size: 2rem;
  }
}

.c-profile__ttl__date {
  display: block;
  margin-bottom: 1.6rem;
}

.c-profile__caption {
  display: table-cell;
  vertical-align: bottom;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.4;
}

@media screen and (min-width: 800px) {
  .c-profile__caption {
    font-size: 1.2rem;
  }
}

.c-profile__caption__name {
  display: block;
  font-size: 1.8rem;
}

@media screen and (min-width: 800px) {
  .c-profile__caption__name {
    font-size: 2rem;
  }
}

.c-profile__caption__name span {
  font-size: 1.6rem;
}

@media screen and (min-width: 800px) {
  .c-profile__caption__name span {
    font-size: 1.8rem;
  }
}

.c-profile__lead {
  position: relative;
  border-top: 1px solid #dddddd;
  font-size: 1.2rem;
  line-height: 1.6;
  padding: 20px 0;
}

@media screen and (min-width: 800px) {
  .c-profile__lead {
    padding: 40px 0;
    font-size: 1.4rem;
    line-height: 1.8;
  }
}

.c-profile__lead:after {
  position: absolute;
  top: -10px;
  left: 16%;
  content: "";
  display: block;
  width: 17px;
  height: 10px;
  background: url("/_assets/img/common/line_profile_arrow.png") no-repeat left top;
}

@media screen and (min-width: 800px) {
  .c-profile__lead:after {
    left: 75px;
  }
}

.top__pagetop {
  text-align: center;
}

@media screen and (min-width: 800px) {
  .top__pagetop {
    text-align: right;
    padding: 0 30px;
    margin: 0 0 5px;
  }
}

.top__pagetop a {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(/_assets/img/common/btn_pagetop.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.top-section {
  margin: 40px 0;
}

@media screen and (min-width: 800px) {
  .top-section {
    margin: 80px 0;
  }
}

@media screen and (min-width: 800px) {
  .top-section__inner {
    padding: 0 10px;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.top-section__ttl {
  font-size: 1.8rem;
  text-align: center;
  padding: 0 0 15px;
  margin: 0 0 40px;
  background-image: url(/_assets/img/common/line.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 43px;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .top-section__ttl {
    font-size: 2rem;
    padding: 0 0 30px;
    margin: 0 0 35px;
  }
}

.top-mv {
  width: 100%;
  margin: -45px 0 40px;
}

@media screen and (min-width: 800px) {
  .top-mv {
    margin: 0 0 80px;
  }
}

.top-mv__slide {
  width: 100%;
  height: 275px;
  background-position: center;
  background-size: cover;
  position: relative;
}

@media screen and (min-width: 800px) {
  .top-mv__slide {
    height: auto;
  }
}

.top-mv__slide img {
  display: none;
}

@media screen and (min-width: 800px) {
  .top-mv__slide img {
    display: block;
    width: 100%;
  }
}

.top-mv .slick-dots {
  margin: 15px 0 0;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .top-mv .slick-dots {
    margin: 25px 0 0;
  }
}

.top-mv .slick-dots li {
  display: inline-block;
  font-size: 0;
}

.top-mv .slick-dots li.slick-active button {
  background-color: #332e2b;
  border: 1px solid #332e2b;
}

.top-mv .slick-dots button {
  display: inline-block;
  width: 8px;
  height: 8px;
  font-size: 0;
  background: none;
  border: none;
  border: 1px solid #c9c7c6;
  border-radius: 50%;
  padding: 0;
  margin: 0 6px;
}

@media screen and (min-width: 800px) {
  .top-mv .slick-dots button {
    width: 10px;
    height: 10px;
    margin: 0 7px;
    cursor: pointer;
    transition: background-color 0.4s;
  }
  .top-mv .slick-dots button:hover {
    background-color: rgba(201, 199, 198, 0.3);
  }
}

.top-news {
  padding: 0 30px;
  margin: 0 0 40px;
}

.top-menu {
  color: #fff;
}

@media screen and (min-width: 800px) {
  .top-menu {
    height: 550px;
    background-position: center;
    background-size: cover;
    background-image: url(/_assets/img/top/menu_bg.png);
  }
}

@media screen and (min-width: 800px) {
  .top-menu .top-section__inner {
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  .top-menu__list {
    height: 550px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
  }
}

.top-menu__item {
  height: 210px;
  background-position: center;
  background-size: cover;
  position: relative;
}

@media screen and (min-width: 800px) {
  .top-menu__item {
    width: 33.3333%;
    height: 100%;
  }
}

.top-menu__item.is-pray {
  background-image: url(/_assets/img/top/menu01.jpg);
}

@media screen and (min-width: 800px) {
  .top-menu__item.is-pray {
    background-image: url(/_assets/img/top/menu01-pc.jpg);
  }
}

.top-menu__item.is-know {
  background-image: url(/_assets/img/top/menu02.jpg);
}

@media screen and (min-width: 800px) {
  .top-menu__item.is-know {
    background-image: url(/_assets/img/top/menu02-pc.jpg);
  }
}

.top-menu__item.is-know .top-menu__ttl {
  right: auto;
  left: 15px;
}

@media screen and (min-width: 800px) {
  .top-menu__item.is-know .top-menu__ttl {
    left: auto;
    right: 50%;
  }
}

.top-menu__item.is-know .top-menu__txt {
  text-align: left;
  right: auto;
  left: 75px;
}

.top-menu__item.is-see {
  background-image: url(/_assets/img/top/menu03.jpg);
}

@media screen and (min-width: 800px) {
  .top-menu__item.is-see {
    background-image: url(/_assets/img/top/menu03-pc.jpg);
  }
}

.top-menu__link {
  display: block;
  height: 210px;
}

@media screen and (min-width: 800px) {
  .top-menu__link {
    height: 100%;
  }
  .top-menu__link.is-disable {
    pointer-events: none;
  }
}

.top-menu__ttl {
  display: block;
  font-size: 4.2rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 25px;
  right: 15px;
}

@media screen and (min-width: 800px) {
  .top-menu__ttl {
    bottom: 50%;
    right: 50%;
    margin: 0 -0.5em -1em 0;
    font-size: 3.7rem;
    vertical-align: middle;
  }
}

.top-menu__ttl span {
  font-size: 5.4rem;
}

@media screen and (min-width: 800px) {
  .top-menu__ttl span {
    font-size: 4.8rem;
  }
}

.top-menu__txt {
  text-align: right;
  position: absolute;
  bottom: 25px;
  right: 77px;
  font-size: 1.2rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .top-menu__txt {
    display: none;
  }
}

.top-menu__img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) translate3d(0, 0, 0);
  width: 980px;
  max-width: none;
  transition: width 25s linear;
}

.top-menu .top-menu__cover.is-show .top-menu__img {
  width: 1274px;
}

.top-menu__cover {
  display: none;
}

@media screen and (min-width: 800px) {
  .top-menu__cover {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 2;
    width: 100%;
    max-width: 980px;
    height: 550px;
    overflow: hidden;
  }
  .top-menu__cover:before, .top-menu__cover:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    position: absolute;
    top: 0;
    z-index: 3;
  }
  .top-menu__cover:before {
    left: 33.3333%;
  }
  .top-menu__cover:after {
    left: 66.6666%;
  }
}

.top-menu__cover.is-current {
  z-index: 2;
}

.top-menu__cover.is-pray .top-menu__cover-ttl {
  left: 15%;
}

.top-menu__cover.is-pray .top-menu__cover-txt {
  left: 15%;
  margin: 0 0 0 -3.5em;
}

.top-menu__cover.is-know .top-menu__cover-ttl {
  left: 50%;
}

.top-menu__cover.is-know .top-menu__cover-txt {
  left: 50%;
  margin: 0 0 0 -2.5em;
}

.top-menu__cover.is-see .top-menu__cover-ttl {
  left: 83%;
}

.top-menu__cover.is-see .top-menu__cover-txt {
  left: 83%;
  margin: 0 0 0 -2.5em;
}

.top-menu__cover-ttl {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  top: 100px;
  z-index: 5;
  font-size: 3.7rem;
  margin: 0 0 0 -0.5em;
}

.top-menu__cover-ttl span {
  font-size: 4.8rem;
}

.top-menu__cover-txt {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  top: 240px;
  z-index: 5;
  line-height: 2;
}

.top-event {
  padding: 0 15px;
  border-bottom: 1px solid #e4e3e3;
}

.top-event__list {
  padding: 0 0 50px;
}

@media screen and (min-width: 800px) {
  .top-event__list {
    padding: 0 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (min-width: 800px) {
  .top-event__item {
    width: 30.5%;
  }
}

.top-event__item + .top-event__item {
  margin: 30px 0 0;
}

@media screen and (min-width: 800px) {
  .top-event__item + .top-event__item {
    margin: 0;
  }
}

.top-event__link {
  display: block;
  min-height: 130px;
  padding: 0 0 15px 180px;
  position: relative;
}

@media screen and (min-width: 800px) {
  .top-event__link {
    padding: 0 0 40px;
  }
  .top-event__link:hover .top-event__img:after {
    opacity: 1;
  }
  .top-event__link:hover .top-event__ttl {
    text-decoration: underline;
  }
  .top-event__link:hover:after {
    right: -10px;
  }
}

.top-event__link:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 6px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 72px;
}

@media screen and (min-width: 800px) {
  .top-event__link:after {
    transition: right 0.4s;
  }
}

.top-event__img {
  width: 165px;
  height: 110px;
  text-align: center;
  background-color: rgba(201, 199, 198, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 800px) {
  .top-event__img {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 0 25px;
    transition: background-color 0.4s;
  }
  .top-event__img:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.05);
    transition: opacity 0.4s;
  }
}

.top-event__img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.top-event__ttl {
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 0 0 10px;
}

@media screen and (min-width: 800px) {
  .top-event__ttl {
    text-align: center;
    font-size: 1.8rem;
    margin: 0 0 5px;
  }
}

.top-event__date {
  font-size: 1rem;
  position: absolute;
  top: 120px;
  left: 0;
  width: 165px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .top-event__date {
    font-size: 1.2rem;
    width: auto;
    position: static;
    margin: 0 0 20px;
  }
}

.top-event__txt {
  font-size: 1.2rem;
  line-height: 1.3;
}

@media screen and (min-width: 800px) {
  .top-event__txt {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.top-apply {
  padding: 0 15px 40px;
  border-bottom: 1px solid #e4e3e3;
}

@media screen and (min-width: 800px) {
  .top-apply {
    padding: 0 0 80px;
  }
}

.top-apply__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-apply__item {
  width: 47%;
  margin: 0 0 20px;
}

@media screen and (min-width: 800px) {
  .top-apply__item {
    width: 22%;
  }
}

.top-apply__item:nth-last-child(-n+2) {
  margin: 0;
}

.top-apply__link {
  display: block;
}

@media screen and (min-width: 800px) {
  .top-apply__link:hover .top-apply__ttl {
    text-decoration: underline;
  }
  .top-apply__link:hover .top-apply__img:after {
    opacity: 1;
  }
}

@media screen and (min-width: 800px) {
  .top-apply__img {
    margin: 0 0 25px;
    position: relative;
  }
  .top-apply__img:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.4s;
  }
}

.top-apply__img img {
  display: block;
  width: 100%;
}

.top-apply__ttl {
  font-size: 1.4rem;
  text-align: center;
  margin: 10px 0;
}

@media screen and (min-width: 800px) {
  .top-apply__ttl {
    margin: 0 0 15px;
    font-size: 1.6rem;
  }
}

.top-apply__txt {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .top-apply__txt {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.top-relation {
  padding: 0 15px;
}

@media screen and (min-width: 800px) {
  .top-relation {
    margin: 0 0 40px;
  }
}

.top-relation__shuha {
  display: block;
  background-color: #fff;
  border: 1px solid #e8e7e7;
  padding: 20px 0 16px;
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .top-relation__shuha {
    width: 380px;
    margin: 0 auto 55px;
    padding: 23px 0 20px;
    transition: background-color 0.4s;
  }
  .top-relation__shuha:hover {
    background-color: #f2f2f2;
  }
}

.top-relation__shuha img {
  display: block;
  width: 216px;
  margin: 0 auto 10px;
}

@media screen and (min-width: 800px) {
  .top-relation__shuha img {
    width: 238px;
    margin: 0 auto 15px;
  }
}

.top-relation__shuha span {
  display: block;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (min-width: 800px) {
  .top-relation__shuha span {
    line-height: 1;
  }
}

.top-relation__shuha span:after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 1em;
  margin: 0 0 0 3px;
  background-image: url(/_assets/img/common/icon_blank.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}

@media screen and (min-width: 800px) {
  .top-relation__shuha span:after {
    width: 14px;
    background-size: 14px;
    margin: 0 0 0 10px;
  }
}

.top-relation__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-relation__item {
  width: 47%;
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .top-relation__item {
    width: 22%;
    margin: 0;
  }
}

.top-relation__item:nth-last-child(-n+2) {
  margin: 0;
}

.top-relation__link {
  display: block;
  padding: 0 0 0 38px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 30px;
  min-height: 30px;
}

@media screen and (min-width: 800px) {
  .top-relation__link {
    padding: 0 0 0 55px;
    background-size: 40px;
    min-height: 40px;
  }
  .top-relation__link:hover .top-relation__ttl {
    text-decoration: underline;
  }
}

.top-relation__link.is-01 {
  background-image: url(/_assets/img/top/relation01.png);
}

.top-relation__link.is-02 {
  background-image: url(/_assets/img/top/relation02.png);
}

.top-relation__link.is-03 {
  background-image: url(/_assets/img/top/relation03.png);
}

.top-relation__link.is-04 {
  background-image: url(/_assets/img/top/relation04.png);
}

.top-relation__lead {
  display: block;
  font-size: 1rem;
  margin: 0 0 5px;
}

@media screen and (min-width: 800px) {
  .top-relation__lead {
    font-size: 1.2rem;
    margin: 0 0 8px;
  }
}

.top-relation__ttl {
  font-size: 1.6rem;
}

@media screen and (min-width: 800px) {
  .top-relation__ttl {
    font-size: 1.5rem;
  }
}

.top-relation__ttl:after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 1em;
  margin: 0 0 0 5px;
  background-image: url(/_assets/img/common/icon_blank.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
}

@media screen and (min-width: 800px) {
  .top-relation__ttl:after {
    width: 14px;
    background-size: 14px;
    margin: 0 0 0 10px;
  }
}

.article {
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .article {
    margin: 0 0 80px;
  }
}

.article .article__date {
  text-align: right;
  margin: 0 0 15px;
}

@media screen and (min-width: 800px) {
  .article .article__date {
    font-size: 1.6rem;
    margin: 0 0 30px;
  }
}

.article h1 {
  margin: 0 0 40px;
  font-size: 2.1rem;
  font-weight: bold;
  border-bottom: 1px solid #d4d2d2;
  padding: 0 0 10px;
  line-height: 1.4;
  position: relative;
}

@media screen and (min-width: 800px) {
  .article h1 {
    font-size: 2.8rem;
    margin: 0 0 80px;
    padding: 0 0 20px;
  }
}

.article h1:before, .article h1:after {
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  background-color: #d4d2d2;
  border-radius: 10px;
  position: absolute;
  bottom: -2px;
}

.article h1:before {
  left: 0;
}

.article h1:after {
  right: 0;
}

.article h2 {
  font-size: 1.8rem;
  margin: 0 0 40px;
  border-left: 3px solid #332c2b;
  padding: 3px 0 3px 6px;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .article h2 {
    font-size: 2rem;
    margin: 0 0 80px;
  }
}

.article h3 {
  margin: 0 0 40px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (min-width: 800px) {
  .article h3 {
    font-size: 2rem;
    margin: 0 0 80px;
  }
}

.article ul {
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .article ul {
    margin: 0 0 80px;
  }
}

.article a {
  display: block;
  margin: 0 0 20px;
  background-color: rgba(55, 50, 47, 0.9);
  color: #fff;
  text-align: center;
  padding: 15px 15px 15px 15px;
  position: relative;
  min-width: 100%;
}

@media screen and (min-width: 800px) {
  .article a {
    min-width: 310px;
    width: 310px;
    margin: 0 0 40px;
    padding: 20px 45px;
    transition: background-color 0.4s;
  }
  .article a:hover {
    background-color: #37322f;
  }
  .article a:hover:after {
    right: 10px;
  }
}

.article a:after {
  content: '';
  width: 24px;
  height: 6px;
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  background-image: url(/_assets/img/common/icon_arrow-right.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 71px;
  margin: -3px 0 0;
  transition: right 0.3s;
}

.article a.is-blank {
  background-image: url(/_assets/img/common/icon_blank.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 12px;
}

@media screen and (min-width: 800px) {
  .article a.is-blank {
    background-size: 16px;
    background-position: right 15px center;
  }
}

.article a.is-blank:after {
  display: none;
}

.article a.is-pdf {
  background-image: url(/_assets/img/common/icon_pdf.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;
}

.article a.is-pdf:after {
  display: none;
}

.article p {
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .article p {
    margin: 0 0 80px;
  }
}

.article img {
  display: block;
  margin: 0 auto 40px;
  max-width: 100%;
}

@media screen and (min-width: 800px) {
  .article img {
    margin: 0 auto 80px;
  }
}

.article table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.4rem;
  border: 1px solid #dddddd;
  margin: 0 0 40px;
}

@media screen and (min-width: 800px) {
  .article table {
    margin: 0 0 80px;
  }
}

.article table tr:first-child th {
  text-align: center;
}

.article table th,
.article table td {
  text-align: left;
}

.article table th {
  min-width: 8em;
}

.u-text--align-left {
  text-align: left !important;
}

.u-text--align-center {
  text-align: center !important;
}

.u-text--align-right {
  text-align: right !important;
}

.u-text--weight-bold {
  font-weight: bold !important;
}

.u-text--weight-normal {
  font-weight: normal !important;
}

.u-text--size-big {
  font-size: 1.2em !important;
}

.u-text--size-small {
  font-size: 0.8em !important;
}

@media screen and (min-width: 800px) {
  .u-show--sp {
    display: none !important;
  }
}

@media screen and (max-width: 799px) {
  .u-show--pc {
    display: none !important;
  }
}
