.l-header{
  background-image: url(/_assets/img/common/bg-sp.png);
  background-size: 50px;
  // position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 65px;
  padding: 0 20px;
  box-shadow: 0 1px 1px 1px rgba(#000, 0.1);

  @include breakpoint(pc){
    height: 130px;
    position: relative;
    z-index: 1;
  }

  &__inner{
    @include breakpoint(pc){
      @include cfx;
      padding: 0 10px;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__logo{

    @include breakpoint(pc){
      float: left;
      width: 370px;
      width: 36%;
    }

    a{
      display: inline-block;
      background-image: url(/_assets/img/common/logo-sp.png);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 117px;
      line-height: 65px;
      padding: 0 0 0 130px;
      font-size: 1.3rem;

      @include breakpoint(pc){
        display: block;
        background-image: url(/_assets/img/common/logo-pc.png);
        background-position: 0 bottom;
        background-size: 217px;
        width: 217px;
        height: 96px;
        padding: 80px 0 0 90px;
        line-height: 1;
        font-size: 1.6rem;
      }
    }
  }

  &__btn{
    display: block;
    width: 32px;
    height: 17px;
    position: absolute;
    top: 24px;
    right: 20px;
    background-image: url(/_assets/img/common/btn_nav.png);
    background-repeat: no-repeat;
    background-size: 100%;

    @include breakpoint(pc){
      display: none;
    }

    &.is-open{
      background-image: url(/_assets/img/common/btn_nav-open.png);
    }
  }
}

.l-header-fix{
  display: none;

  @include breakpoint(pc){
    display: block;
    position: fixed;
    top: -80px;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 1px 1px 1px rgba(#000, 0.1);
    transition: top 0.3s;

    &.is-show{
      top: 0;
    }
  }

  &__inner{
    @include cfx;
    padding: 0 10px;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__logo{
    display: block;
    float: left;
    width: 36%;
    height: 70px;
    line-height: 70px;
    padding: 0 0 0 170px;

    background-image: url(/_assets/img/common/logo-fixed.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 160px;
  }

  &__menu{
    float: right;
    max-width: 610px;
    width: 63%;
    height: 70px;
  }
}

.l-header-fix-main{
  width: 54%;
  height: 70px;
  float: left;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;

  &__item{
    display: table-cell;
    border-right: 1px solid #d4d2d2;
    border-left: 1px solid #d4d2d2;
    vertical-align: top;

    &:hover{
      background-color: #f1f1f1;

       > .l-header-fix-main__list{
        display: block;
        height: 200px;
        opacity: 1;
        transition: opacity 0.6s;
        z-index: 2;
      }
    }
  }

  &__ttl{
    display: block;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.4s;

    > span{
      display: block;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      font-size: 1.6rem;
      line-height: 23px;
      width: 23px;

      span{
        font-size: 2.3rem;
      }
    }
  }

  &__list{
    display: block !important;
    margin: 0;
    background-image: url(/_assets/img/common/bg_nav.png);
    width: 100%;
    height: 200px;
    height: 0;
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 0;
    opacity: 0;
    transition: opacity 0.2s, height 0s 0.2s;
    overflow: hidden;

    .l-header-fix-main__link:last-child{

      @include breakpoint(pc){
        border-right: 1px solid #62676e;
      }
    }

    &.is-pray{
      @include breakpoint(pc){
        padding-right: 130px;
      }
    }

    &.is-know{
      @include breakpoint(pc){
        padding-left: 90px;
      }
    }

    &.is-see{
      @include breakpoint(pc){
        padding: 0 0 0 310px;
      }
    }
  }

  &__link{
    display: inline-block;
    vertical-align: top;
    height: 200px;
    border-left: 1px solid #62676e;
  }

  &__txt{
    display: block;
    position: relative;
    line-height: 50px;
    text-align: center;
    font-size: 1.6rem;

    @include breakpoint(pc){
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      width: 50px;
      line-height: 50px;
      transition: background-color 0.4s;
      text-align: left;
      height: 100%;
      padding-top: 35px;

      &:hover{
        background-color: rgba(#000, 0.25);
      }
    }
  }
}

.l-header-fix-sub{
  width: 27%;
  float: right;
  display: table;

  float: right;
  width: 40%;
  height: 70px;

  &__item{
    display: table-cell;
    vertical-align: middle;
    text-align: right;

    &:first-child{
      padding: 0 10px 0 0;
    }

    &:last-child{
      width: 120px;
    }
  }

  &__link{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
    padding: 0 0 0 27px;

    &:hover{
      text-decoration: underline;
    }

    &.is-access{
      background-image: url(/_assets/img/common/icon_access2.png);
    }

    &.is-apply{
      background-image: url(/_assets/img/common/icon_apply2.png);
    }
  }
}
