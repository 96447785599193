html{
  font-size: 10px;
  font-weight: 400;
  color: #332c2b;
  background-image: url(/_assets/img/common/bg-sp.png);
  background-size: 50px;
}

body{
  font-size: 1.6rem;
  font-family: "yu-mincho-pr6n", sans-serif;
  font-style: normal;
  font-weight: 400;
}

a{
  color: inherit;
}

img{
  max-width: 100%;
}
