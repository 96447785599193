.c-profile{
  @include breakpoint(pc){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }

  &__block {
    margin-bottom: 50px;
    position: relative;
    @include breakpoint(pc){
      width: 50%;
      padding: 0 20px;
    margin-bottom: 90px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: url("/_assets/img/common/line_profile.png") no-repeat left top;
      @include breakpoint(pc){
        width: calc(100% - 40px);
        left: 20px;
      }
    }
  }

  &__inner{
    display: table;
    height: 150px;
    margin-bottom: 30px;
    @include breakpoint(pc){
      margin-bottom: 40px;
      height: 200px;
    }
  }
  &__img{
    width: 34.0%;
    display: table-cell;
    img{
      min-width: 120px;
      width: 100%;
      @include breakpoint(pc){
        min-width: 160px;
      }
    }
  }

  &__info{
    display: table-cell;
    padding: 0;
    padding: 0 0 0 20px;
    vertical-align: top;
    @include breakpoint(pc){
      padding: 0 0 0 32px;
    }

    &__inner{
      display: table;
      height: 100%;
      &__row{
        display: table-row;
      }
    }
  }

  &__ttl{
    display: table-cell;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.4;
    padding-bottom: 1rem;
    @include breakpoint(pc){
      font-size: 2rem;
    }
    &__date{
      display: block;
      margin-bottom: 1.6rem;
    }

  }
  &__caption{
    display: table-cell;
    vertical-align: bottom;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.4;
    @include breakpoint(pc){
      font-size: 1.2rem;
    }
    &__name{
      display: block;
      font-size: 1.8rem;
      @include breakpoint(pc){
        font-size: 2rem;
      }
      span {
        font-size: 1.6rem;
        @include breakpoint(pc){
          font-size: 1.8rem;
        }

      }
    }
  }

  &__lead{
    position: relative;
    border-top: 1px solid #dddddd;
    font-size: 1.2rem;
    line-height: 1.6;
    padding: 20px 0;
    @include breakpoint(pc){
      padding: 40px 0;
      font-size: 1.4rem;
      line-height: 1.8;
    }
    &:after {
      position: absolute;
      top: -10px;
      left: 16%;
      content: "";
      display: block;
      width: 17px;
      height: 10px;
      background: url("/_assets/img/common/line_profile_arrow.png") no-repeat left top;
      @include breakpoint(pc){
        left: 75px;
      }
    }
  }
}
