.u-text--align-left{
  text-align: left !important;
}

.u-text--align-center{
  text-align: center !important;
}

.u-text--align-right{
  text-align: right !important;
}

.u-text--weight-bold{
  font-weight: bold !important;
}

.u-text--weight-normal{
  font-weight: normal !important;
}

.u-text--size-big{
  font-size: 1.2em !important;
}

.u-text--size-small{
  font-size: 0.8em !important;
}

.u-show--sp{
  @include breakpoint(pc){
    display: none !important;
  }
}

.u-show--pc{
  @include breakpoint(sp){
    display: none !important;
  }
}


