.c-table{

  table{
    width: 100%;
    border-collapse: collapse;
    font-size: 1.2rem;
    border: 1px solid #dddddd;

    @include breakpoint(pc){
      font-size: 1.4rem;
    }
  }

  .is-left{
    text-align: left;
  }

  .is-right{
    text-align: right;
  }

  th,
  td{
    text-align: center;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    border-left: 1px dotted #dddddd;
    border-right: 1px dotted #dddddd;
    padding: 10px;
    vertical-align: middle;

    @include breakpoint(pc){
      padding: 15px 20px;
    }
  }

  th{
    font-weight: 400;
    background-color: #f1f1f1;
  }

  &.is-scroll{
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @include breakpoint(pc){
      overflow: hidden;
    }

    table{
      width: 980px;

      @include breakpoint(pc){
        width: 100%;
      }
    }
  }
}

