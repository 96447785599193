.c-heading{

  &__icon{
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #474240;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    line-height: 27px;
    position: relative;
    top: -2px;
    margin: 0 10px 0 0;
    vertical-align: middle;
    font-weight: normal;

    @include breakpoint(pc){
      top: -4px;
    }

    &.is-car,
    &.is-train{
      width: 30px;
      height: 30px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    &.is-car{
      background-image: url(/_assets/img/common/icon_car.png);
    }

    &.is-train{
      background-image: url(/_assets/img/common/icon_train.png);
    }
  }

  &__label{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1;
    border: 1px solid #787878;
    position: relative;
    top: 0px;
    width: 72px;
    text-align: center;
    padding: 2px 0;
    margin: 0 0 0 10px;
    font-weight: normal;

    @include breakpoint(pc){
      top: -2px;
    }

    & + .c-heading__label{
      margin: 0 0 0 -1px;
    }
  }
}


.c-heading--lv1{
  overflow: hidden;
  font-size: 2.1rem;
  text-align: center;
  position: relative;
  padding: 0 40px;
  margin: 0 -30px;
  font-weight: bold;

  @include breakpoint(pc){
    font-size: 2.8rem;
  }

  &:before,
  &:after{
    content: '';
    display: block;
    width: 31px;
    height: 3px;
    background-image: url(/_assets/img/common/line.png);
    background-repeat: no-repeat;
    position: absolute;
    background-size: 43px;
    top: 50%;
    margin: -2px 0 0;

    @include breakpoint(pc){
      display: none;
    }
  }

  &:before{
    left: 0;
    background-position: right center;
  }

  &:after{
    right: 0;
    background-position: left center;
  }

  span{
    @include breakpoint(pc){
      display: inline-block;
      position: relative;
      padding: 0 70px;
    }

    &:before,
    &:after{
      content: '';
      display: none;
      width: 43px;
      height: 3px;
      background-image: url(/_assets/img/common/line.png);
      background-repeat: no-repeat;
      position: absolute;
      background-size: 43px;
      top: 50%;
      margin: -1px 0 0;

      @include breakpoint(pc){
        display: block;
      }
    }

    &:before{
      left: 0;
      background-position: right center;
    }

    &:after{
      right: 0;
      background-position: left center;
    }
  }
}

.c-heading--lv2{
  font-size: 1.8rem;
  text-align: center;
  padding: 0 0 15px;
  background-image: url(/_assets/img/common/line.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 43px;
  font-weight: bold;

  @include breakpoint(pc){
    font-size: 2rem;
    padding: 0 0 25px;
  }

  &__icon{
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #474240;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    line-height: 27px;
    position: relative;
    top: -2px;
    margin: 0 10px 0 0;

    @include breakpoint(pc){
      top: -4px;
    }
  }

  &__label{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1;
    border: 1px solid #787878;
    position: relative;
    top: 0px;
    width: 72px;
    text-align: center;
    padding: 2px 0;
    margin: 0 0 0 10px;

    @include breakpoint(pc){
      top: -2px;
    }

    & + .c-heading--lv4__label{
      margin: 0 0 0 -1px;
    }
  }
}

.c-heading--lv3{
  font-size: 1.8rem;
  font-weight: bold;

  @include breakpoint(pc){
    font-size: 2rem;
  }

  &__icon{
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #474240;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    line-height: 27px;
    position: relative;
    top: -2px;
    margin: 0 10px 0 0;

    @include breakpoint(pc){
      top: -4px;
    }
  }

  &__label{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1;
    border: 1px solid #787878;
    position: relative;
    top: 0px;
    width: 72px;
    text-align: center;
    padding: 2px 0;
    margin: 0 0 0 10px;

    @include breakpoint(pc){
      top: -2px;
    }

    & + .c-heading--lv3__label{
      border-left: none;
      margin: 0;
    }
  }
}

// .c-heading--lv4{
//   font-size: 1.8rem;

//   @include breakpoint(pc){
//     font-size: 2.4rem;
//   }

//   &__icon{
//     display: inline-block;
//     width: 27px;
//     height: 27px;
//     background-color: #474240;
//     color: #fff;
//     text-align: center;
//     font-size: 1.4rem;
//     line-height: 27px;
//     position: relative;
//     top: -2px;
//     margin: 0 10px 0 0;

//     @include breakpoint(pc){
//       top: -4px;
//     }
//   }

//   &__label{
//     display: inline-block;
//     font-size: 1.4rem;
//     line-height: 1;
//     border: 1px solid #787878;
//     position: relative;
//     top: 0px;
//     width: 72px;
//     text-align: center;
//     padding: 2px 0;
//     margin: 0 0 0 10px;

//     @include breakpoint(pc){
//       top: -2px;
//     }

//     & + .c-heading--lv4__label{
//       border-left: none;
//       margin: 0;
//     }
//   }
// }

