.c-link{}

@keyframes c-more-link {
  0% {
    background-position: right 72px bottom;
  }
  100% {
    background-position: right 0 bottom;
  }
}


.c-more-link{
  text-align: right;
  font-size: 1.4rem;

  @include breakpoint(pc){
    padding: 10px 0 0;
  }

  a{
    display: inline-block;
    padding: 0 0 10px;
    background-image: url(/_assets/img/common/icon_arrow-right.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 72px;

    @include breakpoint(pc){
      background-size: 72px;
      background-position: right 0 bottom;

      &:hover{
        animation-duration: 0.4s;
        animation-name: c-more-link;
      }
    }
  }
}




