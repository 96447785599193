.l-main{
  width: 100%;
  overflow: hidden;
  padding: 45px 0 0;

  @include breakpoint(pc){
    padding: 0;
  }
}

