.c-migration{
  margin: 0 -30px;
  border-top: 1px solid #e6e5e4;

  @include breakpoint(pc){
    margin: 0;
  }

  &__item{
    height: 90px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    padding: 0 30px;
    border-bottom: 1px solid #e6e5e4;

    @include breakpoint(pc){
      height: 120px;
      padding: 0;
      background: none !important;
    }

    &:nth-of-type(even){
      @include breakpoint(pc){
        .c-migration__bg{
          background-position: left 50px center;
        }
      }
    }
  }

  &__link{
    display: block;
    height: 100%;
    background-image: url(/_assets/img/common/icon_arrow-right2.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 16px;

    @include breakpoint(pc){
      position: relative;
      background: none;

      &:hover{

        &:before{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(#ccc, 0.1);
        }

        .c-migration__inner:after{
          right: -16px;
        }
      }
    }
  }

  &__inner{
    height: 100%;

    @include breakpoint(pc){
      height: 120px;
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      position: relative;

      &:before{
        content: '';
        display: block;
        width: 1px;
        height: 60px;
        background-color: #e6e5e4;
        position: absolute;
        top: 50%;
        right: 51%;
        margin: -30px 0 0;
      }

      &:after{
        content: '';
        display: block;
        width: 16px;
        height: 17px;
        position: absolute;
        top: 50%;
        right: 0;
        background-image: url(/_assets/img/common/icon_arrow-right2.png);
        background-repeat: no-repeat;
        background-size: 100%;
        transition: right 0.4s;
        margin: -8px 0 0;
      }
    }
  }

  &__bg{
    height: 100%;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    padding: 0 50px 0 180px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(pc){
      width: 100%;
      padding: 0;
      // padding: 30px 0 30px 330px;
      display: table;
      // flex-direction: row;
      // justify-content: left;
    }
  }

  &__ttl{
    display: block;
    font-size: 1.4rem;
    line-height: 1;
    margin: 0 0 5px;

    @include breakpoint(pc){
      display: table-cell;
      width: 49%;
      padding: 0 30px 0 33%;
      // box-sizing: border-box;
      margin: 0;
      vertical-align: middle;

      font-size: 2rem;
      line-height: 1.4;
      position: relative;
    }

    // rt{
    //   display: none;

    //   @include breakpoint(pc){
    //     display: block;
    //   }
    // }

    // ruby{
    //   ruby-position: over;
    // }
  }

  &__txt{
    display: block;
    font-size: 1rem;
    line-height: 1.6;

    @include breakpoint(pc){
      display: table-cell;
      width: 51%;
      font-size: 1.4rem;
      padding: 0 40px 0 30px;
      vertical-align: middle;
      position: relative;
    }
  }
}
