.c-backnumber-modal{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(#000, 0.2);

  @include breakpoint(pc){
  }

  &__inner{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 10;
    transform: translate(0, -50%);
    max-height: 100%;
    overflow: scroll;
    overflow-scrolling: touch;

    @include breakpoint(pc){
      overflow: hidden;
      max-height: none;
      min-height: 480px;
    }
  }

  &__close{
    display: block;
    width: 32px;
    height: 17px;
    background-image: url(/_assets/img/common/btn_nav-open.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 10px;
    right: 10px;

    @include breakpoint(pc){
      top: 20px;
      right: 20px;
    }
  }

  &__prev{
    display: block;
    width: 16px;
    height: 17px;
    background-image: url(/_assets/img/common/icon_arrow-left.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 50%;
    left: 10px;
    margin: -8px 0 0;

    @include breakpoint(pc){
      left: 50%;
      margin: -8px 0 0 -490px;
    }
  }

  &__next{
    display: block;
    width: 16px;
    height: 17px;
    background-image: url(/_assets/img/common/icon_arrow-right2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 50%;
    right: 10px;
    margin: -8px 0 0;

    @include breakpoint(pc){
      right: 50%;
      margin: -8px -490px 0 0;
    }
  }
}


