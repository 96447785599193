.c-relation{
  border-top: 1px solid #e2e1e1;
  padding: 40px 30px 0;
  margin: 0 0 40px;

  @include breakpoint(pc){
    padding: 80px 0 0;
    margin: 0 0 80px;
  }

  &__list{
    padding: 25px 0 0;

    @include breakpoint(pc){
      padding: 50px 22px 0;
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item{
    border-top: 1px solid #e2e1e1;

    @include breakpoint(pc){
      width: calc(100% / 3);
      border-top: none;
      border-left: 1px solid #e2e1e1;
    }

    &:last-child{
      border-bottom: 1px solid #e2e1e1;

      @include breakpoint(pc){
        border-bottom: none;
        border-right: 1px solid #e2e1e1;
      }
    }
  }

  &__link{
    display: block;
    min-height: 110px;
    position: relative;
    padding: 10px 10px 10px 110px;

    &:after{
      content: '';
      display: block;
      width: 24px;
      height: 6px;
      position: absolute;
      bottom: 10px;
      right: 28px;
      background-image: url(/_assets/img/common/icon_arrow-right.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 72px;
      transition: right 0.4s;

      @include breakpoint(pc){}
    }

    @include breakpoint(pc){
      position: relative;
      padding: 10px 28px 10px 138px;
      transition: background-color 0.4s;

      &:hover{
        background-color: rgba(#ccc, 0.1);

        &:after{
          right: 20px;
        }
      }
    }
  }

  &__img{
    display: block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    left: 10px;

    @include breakpoint(pc){
      display: block;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 10px;
      left: 28px;
    }
  }

  &__ttl{
    font-size: 1.6rem;
    margin: 0 0 10px;

    @include breakpoint(pc){
      padding: 3px 0 0;
      font-size: 1.5rem;
    }
  }

  &__txt{
    font-size: 1.4rem;

    @include breakpoint(pc){
      font-size: 1.3rem;
      line-height: 1.6;
    }
  }
}


