.c-column{
  border: 1px solid #d7d5d5;
  background-color: #fff;
  position: relative;
  padding: 25px;

  @include breakpoint(pc){
    padding: 50px 60px;
  }

  &:before,
  &:after{
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &:before{
    top: -7px;
    left: -7px;
    background-image: url(/_assets/img/common/bg_column-top.png);
  }

  &:after{
    bottom: -7px;
    right: -7px;
    background-image: url(/_assets/img/common/bg_column-bottom.png);
  }

  &__lead{
    font-size: 1.2rem;
    color: #999999;
    text-align: center;
    border-bottom: 1px solid #d7d5d5;
    line-height: 1;
    padding: 0 0 15px;

    @include breakpoint(pc){
      font-size: 1.3rem;
      display: inline-block;
      line-height: 30px;
      border-bottom: none;
      border-right: 1px solid #d7d5d5;
      padding: 0 25px 0 0;
    }
  }

  &__ttl{
    font-size: 1.6rem;
    text-align: center;
    padding: 15px 0 20px;

    @include breakpoint(pc){
      font-size: 1.8rem;
      display: inline-block;
      line-height: 30px;
      padding: 0 0 0 25px;
      margin: 0 0 30px;
    }
  }

  &__txt{
    font-size: 1.2rem;
    line-height: 1.8;

    @include breakpoint(pc){
      font-size: 1.4rem;
      line-height: 2;
    }
  }
}


