.c-flower{
  color: #fff;
  background-position: center;
  background-size: cover;
  background-image: url(/_assets/img/top/menu_bg.png);

  @include breakpoint(pc){
  }

  &__inner{
    padding: 0 30px;
    @include cfx;

    @include breakpoint(pc){
      width: 800px;
      height: 480px;
      display: table;
      margin: 0 auto;
      padding: 0;
    }
  }

  &__img{
    padding: 30px 10px 30px 0;

    @include breakpoint(pc){
      float: none;
      display: table-cell;
      width: 360px;
      padding: 0;
    }

    img{
      width: auto;
      display: block;
      max-width: 100%;
      margin: 0 auto;

      @include breakpoint(pc){
        max-width: none;
        width: 100%;
      }
    }
  }

  &__info{
    padding: 30px 10px;

    @include breakpoint(pc){
      float: none;
      display: table-cell;
      width: 440px;
      padding: 0;
      padding: 0 0 0 60px;
      vertical-align: middle;
    }
  }

  &__ttl{
    font-size: 1.6rem;
    margin: 0 0 20px;

    @include breakpoint(pc){
      font-size: 2rem;
      margin: 0 0 40px;
    }
  }

  &__lead{
    font-size: 1.2rem;
    margin: 0 0 10px;

    @include breakpoint(pc){
      font-size: 1.6rem;
      margin: 0 0 20px;
    }
  }

  &__list{
    @extend .c-list--dot;
    @include cfx;

    li{
      & + li{
        margin: 0;
      }

      width: 50%;
      padding-right: 20px;
      float: left;

      &:nth-of-type(odd){
        clear: both;
      }
    }
  }
}
